import {ErrorMessage, Field, useFormikContext} from 'formik';
import React from 'react';
import DragAndDropUpload from '../../DragDropQuestion.js';
import {getFieldName} from '../../Teacher/TPset/utils.js';
import {ImageWithPopup} from './ImageWithPopup';

export const SQuestionType = ({
                                  question,
                                  questionPoint,
                                  fieldName,
                                  index,
                                  disabledStyle,
                                  isDisabled
                              }) => {
    const {submitForm, setFieldValue} = useFormikContext();

    const handleImageUpload = async event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const imageUrl = reader.result;
                setFieldValue(getFieldName(index, 'answerImageUrl', fieldName), imageUrl);
                setFieldValue(getFieldName(index, 'answerImageS3Key', fieldName), null);
            };
            reader.readAsDataURL(file);

            setTimeout(async () => {
                await submitForm();
            }, 500);
        }
    };

    return (
        <div className="mb-[30px]">
            <p className="text-black-600 text-lg mb-4 whitespace-pre-wrap">{questionPoint}</p>
            {question.imageS3Key && (
                <div className="relative mb-4 mt-2 flex items-center">
                    <ImageWithPopup
                        image={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${question.imageS3Key}`}
                    />
                </div>
            )}

            {question.questionType === 'mcq' && (
                <Field name={getFieldName(index, 'answerMcqOptionId', fieldName)}>
                    {({field, form}) => (
                        <>
                            {question.mcqOptions.map(({id, text}, optionIndex) => (
                                <React.Fragment key={`${question.id}-${optionIndex}`}>
                                    <div className="flex gap-[15px] items-center mb-[15px]">
                                        <input
                                            id={`radio-${question.id}-${optionIndex}`}
                                            type="radio"
                                            disabled={isDisabled}
                                            value={id}
                                            checked={field.value === id}
                                            className={`${
                                                isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                                            } w-5 h-5 bg-white border border-gray-300 rounded-3xl checked:bg-orange-200 checked:border-orange-300 checked:hover:bg-orange-100 checked:hover:border-orange-200 flex items-center justify-center transition-colors duration-200 ease-in-out`}
                                            onChange={async (e) => {
                                                const newValue = e.target.value;
                                                await field.onChange({
                                                    target: {
                                                        name: field.name,
                                                        value: newValue
                                                    }
                                                });
                                                form.submitForm();
                                            }}
                                        />
                                        <label htmlFor={`radio-${question.id}-${optionIndex}`}
                                               className="text-black-600">
                                            {text}
                                        </label>
                                    </div>
                                    <span className="text-red-800 text-xs">
              <ErrorMessage name={getFieldName(index, 'answerMcqOptionId', fieldName)}/>
            </span>
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Field>
            )}

            {question.questionType === 'image' && (
                <>
                    {!question.answerImageS3Key && (
                        <div className="w-full">
                            <DragAndDropUpload handleImageUpload={handleImageUpload} isPublished={isDisabled}/>
                        </div>
                    )}
                    {question.answerImageS3Key && (
                        <div className="relative mb-4 mt-2 flex items-center">
                            <ImageWithPopup
                                image={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${question.answerImageS3Key}`}
                            />
                            <button
                                type="button"
                                disabled={isDisabled}
                                onClick={() => {
                                    setFieldValue(getFieldName(index, 'answerImageUrl', fieldName), null);
                                    setFieldValue(getFieldName(index, 'answerImageS3Key', fieldName), null);

                                    setTimeout(async () => {
                                        submitForm();
                                    }, 500);
                                }}
                            >
                                <img src="/assets/trash.png" className="size-4 ml-2" alt="Delete image"/>
                            </button>
                        </div>
                    )}
                </>
            )}

            {question.questionType === 'text' && (
                <>
                    <Field
                        name={getFieldName(index, 'answerText', fieldName)}
                        as="textarea"
                        placeholder="Type your answer here..."
                        disabled={isDisabled}
                        className={`sexy-border rounded-lg mb-4 focus:ring-0 focus:sexy-border focus:rounded-lg w-full h-36 ${disabledStyle}`}
                        onBlur={submitForm}
                    />
                    <span className="text-red-800 text-xs">
            <ErrorMessage name={getFieldName(index, 'answerText', fieldName)}/>
          </span>
                </>
            )}
        </div>
    );
};
