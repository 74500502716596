import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

import { Loading } from '../../components/Loading';
import { useQueryParams } from '../../shared/hooks/use-query-params.tsx';
import {
  clearAssignment,
  clearSubmissions,
  getAssignmentDetails,
  getSubmissions
} from '../../store/teacherSlice.js';
import { DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE } from '../../utils/pagination.ts';

import TEssaySubmissions from './TEssaySubmissions';
import TFlexibleSubmissions from './TFlexibleSubmissions.js';
import TPdfSubmissions from './TPdfSubmissions.js';

const HomePage = () => {
  const dispatch = useDispatch();

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [pullQuestions, setPullQuestions] = useState(false);
  const [solRecent, setSolRecent] = useState(false);
  const [edited, setEdited] = useState(false);
  const [reviewMode, setReviewMode] = useState('Student');

  const { user } = useAuth();
  let { assignment_id, class_id } = useParams();
  const [section, setSection] = useState('create');

  const {
    data: submissions,
    isLoading: isSubmissionLoading,
    errorMessage: submissionsError
  } = useSelector(state => state?.teacherReducer?.submissions);

  console.log(isSubmissionLoading, 'isSubmissionLoading');

  const {
    data: assignment,
    isLoading: isAssignmentLoading,
    errorMessage: assignmentError
  } = useSelector(state => state?.teacherReducer?.assignment);

  const {
    params: { search, pageNumber, itemsPerPage },
    updateSearchParams
  } = useQueryParams({
    search: '',
    pageNumber: DEFAULT_PAGE_NUMBER,
    itemsPerPage: ITEMS_PER_PAGE[50]
  });

  const getAllSubmissions = () =>
    dispatch(
      getSubmissions({
        assignmentId: assignment_id,
        token: user.session_token,
        search,
        pageNumber: Number(pageNumber),
        itemsPerPage: Number(itemsPerPage)
      })
    );

  const getAssignment = () =>
    dispatch(
      getAssignmentDetails({
        assignmentId: assignment_id,
        token: user.session_token,
        classId: class_id
      })
    );

  useEffect(() => {
    getAssignment();

    return () => {
      dispatch(clearAssignment());
      dispatch(clearSubmissions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageNumber, itemsPerPage, updateTrigger]);

  useEffect(() => {
    if (assignment?.is_assignment_published && assignment?.type !== 'essay') {
      setSection('review');
    }
  }, [assignment]);

  if (isAssignmentLoading || !assignment) {
    return <Loading />;
  }

  if (assignment.type === 'essay') {
    return (
      <TEssaySubmissions
        assignment_id={assignment_id}
        assignment={assignment}
        submissions={submissions}
        isSubmissionLoading={isSubmissionLoading}
        itemsPerPage={itemsPerPage}
        pageNumber={pageNumber}
        submissionsError={submissionsError}
        pullQuestions={pullQuestions}
        setPullQuestions={setPullQuestions}
        setUpdateTrigger={setUpdateTrigger}
        setEdited={setEdited}
        setSection={setSection}
        setSolRecent={setSolRecent}
        setReviewMode={setReviewMode}
        updateSearchParams={updateSearchParams}
      />
    );
  }

  if (assignment.type === 'flexible_problem_set') {
    return (
      <TFlexibleSubmissions
        assignment_id={assignment_id}
        assignment={assignment}
        submissions={submissions}
        solRecent={solRecent}
        isSubmissionLoading={isSubmissionLoading}
        itemsPerPage={itemsPerPage}
        pageNumber={pageNumber}
        submissionsError={submissionsError}
        pullQuestions={pullQuestions}
        reviewMode={reviewMode}
        section={section}
        setPullQuestions={setPullQuestions}
        setSection={setSection}
        setUpdateTrigger={setUpdateTrigger}
        setEdited={setEdited}
        setSolRecent={setSolRecent}
        setReviewMode={setReviewMode}
        updateSearchParams={updateSearchParams}
      />
    );
  }

  return (
    <TPdfSubmissions
      assignment_id={assignment_id}
      assignment={assignment}
      submissions={submissions}
      solRecent={solRecent}
      isSubmissionLoading={isSubmissionLoading}
      itemsPerPage={itemsPerPage}
      pageNumber={pageNumber}
      submissionsError={submissionsError}
      pullQuestions={pullQuestions}
      section={section}
      reviewMode={reviewMode}
      edited={edited}
      setSection={setSection}
      setPullQuestions={setPullQuestions}
      setUpdateTrigger={setUpdateTrigger}
      setEdited={setEdited}
      setSolRecent={setSolRecent}
      setReviewMode={setReviewMode}
      updateSearchParams={updateSearchParams}
    />
  );
};

export default HomePage;
