import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {ErrorMessage, FieldArray, useFormikContext} from 'formik';
import {isString} from 'lodash';
import React from 'react';
import {getFieldName} from '../../Teacher/TPset/utils';

import {TFlexibleAssignmentSubQuestion} from './TFlexibleAssignmentSubQuestion';

export const TFlexibleAssignmentSubQuestionColumn = ({
                                                         question,
                                                         questionIndex,
                                                         questionFieldName,
                                                         subQuestions,
                                                         isPublished,
                                                         fieldName,
                                                         disabledStyle,
                                                         setError,
                                                         setViewedQuestions,
                                                         viewedQuestions,
                                                         copiedRubricItems,
                                                         onCopyRubricItems,
                                                         onPasteRubricItems
                                                     }) => {
    const {submitForm, setFieldValue} = useFormikContext();

    return (
        <div>
            <SortableContext items={subQuestions} strategy={verticalListSortingStrategy}>
                {
                    <FieldArray name={fieldName}>
                        {({remove, push}) => (
                            <div>
                                {subQuestions.map((subQuestion, index) => (
                                    <TFlexibleAssignmentSubQuestion
                                        key={`${subQuestion.id}-${index}`}
                                        index={index}
                                        question={question}
                                        subQuestion={subQuestion}
                                        isPublished={isPublished}
                                        fieldName={fieldName}
                                        deleteSubQuestion={() => {
                                            remove(index);
                                            submitForm();
                                        }}
                                        disabledStyle={disabledStyle}
                                        setError={setError}
                                        viewedQuestions={viewedQuestions}
                                        setViewedQuestions={setViewedQuestions}
                                        copiedRubricItems={copiedRubricItems}
                                        onCopyRubricItems={onCopyRubricItems}
                                        onPasteRubricItems={onPasteRubricItems}
                                    />
                                ))}

                                <div className="flex justify-between flex-row-reverse items-center mt-4">
                  <span className="text-red-800 text-xs">
                    <ErrorMessage name={fieldName}>
                      {msg => {
                          if (isString(msg)) {
                              return msg;
                          } else {
                              msg?.map(obj => {
                                  if (!obj) {
                                      return null;
                                  }

                                  if ('mcqOptions' in obj && isString(obj['mcqOptions'])) {
                                      return obj['mcqOptions'];
                                  }

                                  if ('rubricItems' in obj && isString(obj['rubricItems'])) {
                                      return obj['rubricItems'];
                                  }

                                  return null;
                              });

                              return null;
                          }
                      }}
                    </ErrorMessage>
                  </span>
                                    <button
                                        type="button"
                                        disabled={isPublished}
                                        className="less-sexy-button h-[38px]"
                                        onClick={() => {
                                            const isMcqChanged = question.mcqOptions.some(opt => opt.isCorrect);
                                            const newestId = subQuestions.length > 0
                                                ? subQuestions[subQuestions.length - 1].id + 1
                                                : 1;

                                            if (isMcqChanged && subQuestions.length < 1) {
                                                push({
                                                    id: newestId,
                                                    questionType: question.questionType,
                                                    question: '',
                                                    solution: question.solution,
                                                    rubricItems: question.rubricItems,
                                                    mcqOptions: question.mcqOptions,
                                                    image: null,
                                                    weight: question.weight
                                                });

                                                setFieldValue(
                                                    getFieldName(questionIndex, 'solution', questionFieldName),
                                                    ''
                                                );
                                                setFieldValue(getFieldName(questionIndex, 'weight', questionFieldName), 1);
                                            } else {
                                                push({
                                                    id: newestId,
                                                    questionType: 'text',
                                                    question: '',
                                                    solution: '',
                                                    rubricItems: [
                                                        {descriptor: 'Fully correct', value: '2'},
                                                        {descriptor: 'Partially correct', value: '1'},
                                                        {descriptor: 'Incorrect or no work', value: '0'}
                                                    ],
                                                    mcqOptions: [
                                                        {option: 'A', text: '', isCorrect: false},
                                                        {option: 'B', text: '', isCorrect: false}
                                                    ],
                                                    image: null,
                                                    weight: 1
                                                });
                                            }

                                            setViewedQuestions(
                                                prev =>
                                                    new Map([
                                                        ...prev,
                                                        [question.id, [...(prev.get(question.id) || []), newestId]]
                                                    ])
                                            );

                                            setTimeout(async () => {
                                                await submitForm();
                                            }, 500);
                                        }}
                                    >
                                        Add Sub-question
                                    </button>
                                </div>
                            </div>
                        )}
                    </FieldArray>
                }
            </SortableContext>
        </div>
    );
};
