import React, {useState, useEffect} from 'react';
import {useAuth} from '../../auth/AuthContext';
import {gradeApi} from '../../api.js';

const WaitlistSection = () => {
    const [waitlistEntries, setWaitlistEntries] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const {user} = useAuth();

    const fetchWaitlist = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();
            if (filter) {queryParams.append('approved', filter);}

            const response = await gradeApi.get({
                path: `/api/waitlist/list/?${queryParams.toString()}`,
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });

            setWaitlistEntries(response.data.entries);
            setErrorMessage('');
        } catch (error) {
            setErrorMessage('Failed to fetch waitlist entries: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const approveEntry = async (entryId) => {
        try {
            await gradeApi.post({
                path: `/api/waitlist/approve/${entryId}/`,
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });

            // Refresh the list after approval
            fetchWaitlist();
            setErrorMessage('');
        } catch (error) {
            setErrorMessage('Failed to approve entry: ' + error.message);
        }
    };

    useEffect(() => {
        fetchWaitlist();
    }, [filter]);

    return (
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-6">Waitlist Management</h2>

            {/* Filter */}
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Status Filter
                </label>
                <select
                    className="w-full max-w-xs p-2 border rounded"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                >
                    <option value="">All Entries</option>
                    <option value="true">Approved</option>
                    <option value="false">Not Approved</option>
                </select>
            </div>

            {/* Error Message */}
            {errorMessage && (
                <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
                    {errorMessage}
                </div>
            )}

            {/* Loading State */}
            {loading ? (
                <div className="text-center py-4">Loading waitlist entries...</div>
            ) : (
                /* Waitlist Table */
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Contact Info
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                School & Role
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Additional Info
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {waitlistEntries.map((entry) => (
                            <tr key={entry.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm font-medium text-gray-900">
                                        {entry.first_name} {entry.last_name}
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="text-sm text-gray-900">{entry.email}</div>
                                    <div className="text-sm text-gray-500">{entry.phone}</div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="text-sm text-gray-900">{entry.school_name}</div>
                                    <div className="text-sm text-gray-500">{entry.role}</div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="text-sm text-gray-900 max-w-md break-words">
                                        {entry.additional_info}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                      ${entry.is_approved ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                      {entry.is_approved ? 'Approved' : 'Pending'}
                    </span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                    {!entry.is_approved && (
                                        <button
                                            className="text-indigo-600 hover:text-indigo-900"
                                            onClick={() => approveEntry(entry.id)}
                                        >
                                            Approve
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default WaitlistSection;