import { Check } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, Link, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { apiUrl, gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext';
import { useQueryParams } from '../../shared/hooks/use-query-params.tsx';
import { socket } from '../../socket.js';
import { clearAssignmentStudents, getAssignmentStudents } from '../../store/teacherSlice.js';
import { DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE } from '../../utils/pagination.ts';
import ConfirmationModal from '../ConfirmationModal';
import SUnpublishedFlexibleAssignment from '../Student/SUnpublishedFlexibleAssignment/SUnpublishedFlexibleAssignment';
import AIRegrade from './AIRegrade';
import { PDFView } from './PDFRendered';
import StudentSearchDropdown from './StudentSearchDropdown';

const TQuestionDetails = props => {
  const dispatch = useDispatch();

  const { user } = useAuth();
  const { assignment_id, subquestion_id, submission_id } = useParams();
  const [submissionData, setSubmissionData] = useState({});
  const [examPaper, setExamPaper] = useState([]);
  const [feedbackItems, setFeedbackItems] = useState({});
  const [editableFeedback, setEditableFeedback] = useState({});
  const [message, setMessage] = useState('');
  const feedbackRefs = useRef({});
  const [assignment, setAssignment] = useState(null);
  const [studentName, setStudentName] = useState('');
  const navigate = useNavigate();
  const [isRegradeOpen, setIsRegradeOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [regradeData, setRegradeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSubQuestionId, setActiveSubQuestionId] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isSeen, setIsSeen] = useState(false);
  const [assignedStudentId, setAssignedStudentId] = useState(null);
  const [error, setError] = useState(null);
  const [isStudentAssignmentConfirmationOpen, setIsStudentAssignmentConfirmationOpen] =
    useState(false);
  const [newStudentAssignment, setNewStudentAssignment] = useState(null);
  const [regradeInProgress, setRegradeInProgress] = useState({});
  const pollingInterval = useRef(null);
  const [gradePending, setGradePending] = useState(false);
  const [groupStudents, setGroupStudents] = useState([]);
  const [questionMapping, setQuestionMapping] = useState(null);
  const [isMounting, setIsMounting] = useState(false);
  const [viewedCount, setViewedCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(null);
  const [showAllReviewedPopup, setShowAllReviewedPopup] = useState(false);
  const [showNextSubquestionPopup, setShowNextSubquestionPopup] = useState(false);
  const [nextSubQuestionId, setNextSubQuestionId] = useState(null);
  const [isReloadMessageShown, setIsReloadMessageShown] = useState(false);
  const [assignmentDetails, setAssignmentDetails] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [prevSubmissionId, setPrevSubmissionId] = useState(submission_id);

  const [socketApi, setSocketApi] = useState(socket(user.session_token));
  const [isConnected, setIsConnected] = useState(false);
  const [showConnectionErrorPopup, setShowConnectionErrorPopup] = useState(false);

  const { data: students, isLoading: isAssignmentStudentsLoading } = useSelector(
    state => state?.teacherReducer?.assignmentStudents
  );

  const {
    params: { search, pageNumber: scrollPageNumber, itemsPerPage },
    updateSearchParams
  } = useQueryParams({
    search: '',
    pageNumber: DEFAULT_PAGE_NUMBER,
    itemsPerPage: ITEMS_PER_PAGE.default
  });

  useEffect(() => {
    socketApi.on('connect', () => {
      console.log('connected');
      setIsConnected(true);
      setShowConnectionErrorPopup(false);

      socketApi.emit('assignment-join', {
        assignmentId: assignment_id
      });
    });

    socketApi.on('connect_error', err => {
      setShowConnectionErrorPopup(true);
      setTimeout(() => {
        socketApi.connect();
      }, 1000);
    });

    return () => {
      socketApi.emit('assignment-leave', {
        assignmentId: assignment_id
      });

      socketApi.disconnect();
      socketApi.on('disconnect', () => {
        setIsConnected(false);
        setShowConnectionErrorPopup(false);
      });
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      socketApi.emit('submission-question-join', {
        fullName: `${user.user.first_name} ${user.user.last_name}`,
        submissionId: submission_id,
        id: user.user.id,
        assignmentId: assignment_id,
        questionId: subquestion_id
      });
      setPrevSubmissionId(submission_id);
    }
  }, [submission_id, isConnected]);

  useEffect(() => {
    if (isConnected) {
      socketApi.on('submission-question-teachers', data => {
        setTeachers(data);
      });

      socketApi.on('assignment-details', data => {
        setAssignmentDetails(data);
        console.log(data, 'assignment-details');
      });

      socketApi.on('submission-question-refresh', data => {
        console.log(data);

        if (user.user.id !== data.id) {
          setIsReloadMessageShown(true);
          setTimeout(() => setIsReloadMessageShown(false), 2000);

          getQuestions();
          getAssignmentSubmission();
          getAssignment();
        }
      });

      socketApi.on('errors', data => {
        console.error(data);
      });
    }

    return () => {
      socketApi.removeAllListeners();
    };
  }, [isConnected]);

  useEffect(() => {
    updateSearchParams({
      search: '',
      pageNumber: DEFAULT_PAGE_NUMBER
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assignment?.is_exam && assignment.course_id && submissionData.user) {
      getStudents();
    }

    return () => dispatch(clearAssignmentStudents());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (assignment?.is_exam && assignment.course_id && submissionData.user && !search) {
      getStudents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment, submissionData, scrollPageNumber, itemsPerPage]);

  useEffect(() => {
    fetchGroupStudents();
    getPageMapping();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment]);

  // And modify the useEffect to use forceTrue
  useEffect(() => {
    console.log('useEffect', assignment_id, submission_id);
    if (!isSeen && !isMounting) {
      handleMarkSeen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment_id, submission_id, assignedStudentId, studentName, isMounting, submissionData]);

  useEffect(() => {
    getQuestions();
    getAssignmentSubmission();
    getAssignment();
    return () => stopPolling();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment_id, submission_id, refreshKey]);

  // Update student name when submission data changes
  useEffect(() => {
    if (submissionData.user) {
      setStudentName(`${submissionData.user.first_name} ${submissionData.user.last_name}`);
    }
  }, [submissionData, refreshKey]);

  // Re-fetch data when assignment_id or submission_id changes
  useEffect(() => {
    getAssignmentSubmission();
    getQuestions();
    getAssignment();
  }, [assignment_id, submission_id, refreshKey]);

  useEffect(() => {
    const currentViewedCount = getSubQuestionViewedCount();
    setViewedCount(currentViewedCount);
  }, [examPaper, subquestion_id]);

  const socketSubmissionUpdate = () =>
    socketApi.emit('submission-question-update', {
      submissionId: submission_id,
      assignmentId: assignment_id,
      questionId: subquestion_id,
      id: user.user.id
    });

  // Fetching assignment submission details
  const getAssignmentSubmission = async () => {
    setIsMounting(true);
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/submission/${submission_id}/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      const data = response.data;
      setSubmissionData(data);
      if (data) {
        setFeedbackItems(data.submitted ? data.feedback : {});
        setIsSeen(data.feedback.is_viewed);
        setAssignedStudentId(data.user.id);
        setStudentName(`${data.user.first_name} ${data.user.last_name}`);
      }
    } catch (error) {
      setMessage(error.response.data.error);
    } finally {
      setIsMounting(false);
    }
  };

  const getQuestionMapping = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/question-mapping/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      console.log(response);
      const data = JSON.parse(response.data);
      console.log(JSON.parse(data));
      setQuestionMapping(JSON.parse(data));
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  const getStudents = async () => {
    await dispatch(
      getAssignmentStudents({
        classId: assignment.course_id,
        token: user.session_token,
        search,
        pageNumber: Number(pageNumber),
        itemsPerPage: Number(itemsPerPage)
      })
    );

    setAssignedStudentId(submissionData.user.id);
  };

  const handleConfirmStudentAssignment = async () => {
    try {
      await gradeApi.post({
        path: `/api/assignment/${assignment_id}/submissions/${submission_id}/assign-student/`,
        body: { new_student_id: newStudentAssignment.id },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });

      setAssignedStudentId(newStudentAssignment.id);
      setStudentName(newStudentAssignment.full_name);

      // Refresh the submission data
      getAssignmentSubmission();
      setIsStudentAssignmentConfirmationOpen(false);
    } catch (error) {
      console.error('Failed to assign student:', error);
      setError('Failed to assign student. Please try again.');
    }
  };

  const fetchGroupStudents = async () => {
    try {
      if (!assignedStudentId) {
        return;
      }
      const response = await gradeApi.get({
        path: `/api/assignment/get_students/${assignment.id}/student/${assignedStudentId}`, // Make sure to use assignment.id, not aid
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setGroupStudents(response.data); // Assuming response.data is an array
    } catch (error) {
      console.error('Error fetching group students:', error);
      // Consider setting an error state here instead of using setError
    }
  };

  // replace Mark Reviewed with Mark viewed
  const handleMarkSeen = async (forceTrue = false) => {
    try {
      // If forceTrue is true, we want to mark it as reviewed regardless
      // Otherwise, toggle the current status
      console.log(isSeen);
      console.log(forceTrue);
      console.log(!isSeen);
      const newReviewedStatus = forceTrue === true ? true : !isSeen;
      console.log(newReviewedStatus);
      const response = await gradeApi.post({
        path: `/api/assignment/${assignment_id}/submissions/${submission_id}/feedback/${subquestion_id}/mark-viewed/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setIsSeen(newReviewedStatus);
      setViewedCount(response.data.viewed_count);
    } catch (error) {
      console.error('Failed to update review status:', error);
      setMessage('Failed to update review status.');
    }
  };

  const startPolling = () => {
    if (!pollingInterval.current) {
      pollingInterval.current = setInterval(getQuestions, 5000);
    }
  };

  const stopPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
      if (!isRegradeOpen) {
        window.location.reload();
      }
    }
  };

  const getQuestions = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/questions/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      const questionsFromDB = response.data;
      // console.log(questionsFromDB);
      setExamPaper(questionsFromDB.questions);

      // Check for regrade_in_progress
      const newRegradeStatus = {};
      questionsFromDB.questions.forEach(question => {
        question.subQuestions.forEach(subQuestion => {
          if (subQuestion.regrade_in_progress) {
            newRegradeStatus[subQuestion.id] = true;
          }
        });
      });
      setRegradeInProgress(newRegradeStatus);

      // Start or stop polling based on regrade status
      if (Object.values(newRegradeStatus).some(status => status)) {
        startPolling();
      } else {
        stopPolling();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIsDisabledGradingButton = subQuestionId => {
    return !!assignmentDetails?.activeSubmissionsQuestionRegradeRooms.find(
      obj => obj.id !== user.user.id && obj.questionId === subQuestionId
    );
  };

  // Fetching assignment details
  const getAssignment = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/only/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setAssignment(response.data);
    } catch (error) {
      setMessage('Failed to fetch assignment details');
    }
  };

  const clickRight = () => {
    if (!props.submissionIds || props.submissionIds.length === 0) {
      console.error('No submissions available for navigation');
      return;
    }

    const currentIndex = props.submissionIds.indexOf(Number(submission_id));
    let prevIndex =
      currentIndex === -1
        ? props.submissionIds.length - 1
        : (currentIndex - 1 + props.submissionIds.length) % props.submissionIds.length;

    navigateToSubmission(prevIndex, -1);
  };

  const clickLeft = () => {
    if (!props.submissionIds || props.submissionIds.length === 0) {
      console.error('No submissions available for navigation');
      return;
    }

    const currentIndex = props.submissionIds.indexOf(Number(submission_id));
    let nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % props.submissionIds.length;

    navigateToSubmission(nextIndex, 1);
  };

  const clearEditableFeedback = () => {
    setEditableFeedback({});
  };

  const navigateToSubmission = (startIndex, direction) => {
    if (props.submissionIds.length === 1) {
      return;
    }
    clearEditableFeedback(); // Clear editable feedback before navigation

    let currentIndex = startIndex;
    const maxAttempts = props.submissionIds.length;

    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const nextSubmissionId = props.submissionIds[currentIndex];
      console.log(props.submissionIds);
      if (nextSubmissionId != null) {
        navigate({
          pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${nextSubmissionId}/tquestiondetails/${subquestion_id}`,
          search: `?${createSearchParams({
            search,
            pageNumber: scrollPageNumber,
            itemsPerPage
          })}`
        });
        window.location.reload();
        return;
      }
      currentIndex =
        (currentIndex + direction + props.submissionIds.length) % props.submissionIds.length;
    }

    console.error('No valid submissions found to navigate to');
  };

  const hasRubricItem = (subquestionId, rubricId) => {
    if (!feedbackItems) {
      return false;
    }
    if (!subquestionId) {
      return false;
    }
    if (subquestionId in feedbackItems) {
      let rubricItems = feedbackItems[subquestionId]?.rubric_items;
      for (let i = 0; i < rubricItems.length; i++) {
        if (rubricItems[i].id === rubricId) {
          return true;
        }
      }
    }
    return false;
  };

  const getFeedback = subquestionId => {
    if (!feedbackItems) {
      return '';
    }
    if (subquestionId in feedbackItems) {
      return feedbackItems[subquestionId].feedback;
    }
    return '';
  };

  const getRR = subquestionId => {
    if (!feedbackItems) {
      return '';
    }
    if (subquestionId in feedbackItems) {
      return feedbackItems[subquestionId].regrade_request;
    }
    return '';
  };

  const getRRText = subquestionId => {
    if (!feedbackItems) {
      return '';
    }
    if (subquestionId in feedbackItems) {
      return feedbackItems[subquestionId].regrade_text;
    }
    return '';
  };

  const handleRubricItemChange = (subquestionId, rubricItem) => () => {
    gradeApi
      .post({
        path: `/api/assignment/${assignment_id}/submissions/${submission_id}/grade/`,
        body: {
          subquestionId: subquestionId,
          rubric: rubricItem
        },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      })
      .catch(error => {
        // console.log(error);
        setGradePending(false);
      })
      .then(response => {
        setFeedbackItems(response.data.feedback);
        setSubmissionData({ ...submissionData, grade: response.data.grade });
        setGradePending(false);
        socketSubmissionUpdate();
      });
  };

  const handleFeedbackEdit = subquestionId => {
    setEditableFeedback(prev => ({
      ...prev,
      [subquestionId]: getFeedback(subquestionId) || ''
    }));
  };

  const handleFeedbackChange = (subquestionId, value) => {
    setEditableFeedback(prev => ({
      ...prev,
      [subquestionId]: value
    }));
  };

  const handleFeedbackSave = subquestionId => {
    gradeApi
      .post({
        path: `/api/assignment/${assignment_id}/submissions/${submission_id}/feedback/`,
        body: {
          subquestionId: subquestionId,
          feedback: editableFeedback[subquestionId]
        },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      })
      .catch(error => {
        console.log('Error updating feedback:', error);
      })
      .then(response => {
        if (response) {
          setFeedbackItems(prevFeedbackItems => ({
            ...prevFeedbackItems,
            [subquestionId]: {
              ...prevFeedbackItems[subquestionId],
              feedback: editableFeedback[subquestionId]
            }
          }));

          setEditableFeedback(prev => {
            const { [subquestionId]: _, ...rest } = prev;
            return rest;
          });
          socketSubmissionUpdate();

          // console.log('Feedback updated successfully:', response.data);
        }
      });
  };

  const isEditing = subquestionId => editableFeedback.hasOwnProperty(subquestionId);

  const handleRegradeReq = subquestionId => {
    if (subquestionId in feedbackItems) {
      handleRegradeReqDel(subquestionId);
    }
  };

  const handleRegradeReqDel = subquestionId => {
    const submissionID = submissionData.id;

    gradeApi
      .post({
        path: `/api/assignment/${assignment_id}/submissions/${submissionID}/regrade-request/`,
        body: {
          subquestionId: subquestionId,
          regradeRequest: false
        },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      })
      .catch(error => {
        console.log('Error updating regrade request:', error);
      })
      .then(response => {
        if (response) {
          setFeedbackItems(prevFeedbackItems => ({
            ...prevFeedbackItems,
            [subquestionId]: {
              ...prevFeedbackItems[subquestionId],
              regrade_request: false
            }
          }));

          // console.log('Regrade request updated successfully:', response.data);
        }
      });
  };

  const renderFeedback = feedback => {
    return <div className="mb-2">{feedback}</div>;
  };

  const findNextSubquestion = () => {
    let currentSubQuestionFound = false;
    let foundNextSubQuestionId = null;

    for (const question of examPaper) {
      for (const subQuestion of question.subQuestions) {
        if (currentSubQuestionFound) {
          foundNextSubQuestionId = subQuestion.id;
          break;
        }
        if (subQuestion.id === Number(subquestion_id)) {
          currentSubQuestionFound = true;
        }
      }
      if (foundNextSubQuestionId) {
        break;
      }
    }

    setNextSubQuestionId(foundNextSubQuestionId);
    setShowNextSubquestionPopup(true);

    setTimeout(() => {
      setShowNextSubquestionPopup(false);
      if (foundNextSubQuestionId) {
        navigate({
          pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${submission_id}/tquestiondetails/${foundNextSubQuestionId}`,
          search: `?${createSearchParams({
            search,
            pageNumber: scrollPageNumber,
            itemsPerPage
          })}`
        });
        window.location.reload();
      } else {
        navigate(`/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions`);
        window.location.reload();
      }
    }, 1000);
  };

  const findNextUnseen = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/submissions/next-unseen/${subquestion_id}/?current_submission_id=${submission_id}`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });

      if (response.data.next_submission_id === -1) {
        let currentSubQuestionFound = false;
        let nextSubQuestionId = null;
        for (const question of examPaper) {
          for (const subQuestion of question.subQuestions) {
            if (currentSubQuestionFound) {
              nextSubQuestionId = subQuestion.id;
              break;
            }
            if (subQuestion.id === Number(subquestion_id)) {
              currentSubQuestionFound = true;
            }
          }
          if (nextSubQuestionId) {
            break;
          }
        }
        if (nextSubQuestionId) {
          setShowAllReviewedPopup(true);
          // If there's a next subquestion, navigate to it
          setTimeout(() => {
            setShowAllReviewedPopup(false);
            navigate({
              pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${submission_id}/tquestiondetails/${nextSubQuestionId}`,
              search: `?${createSearchParams({
                search,
                pageNumber: scrollPageNumber,
                itemsPerPage
              })}`
            });
            window.location.reload();
          }, 1000);
        } else {
          // Show popup
          setShowAllReviewedPopup(true);
          // Wait 3 seconds then navigate
          setTimeout(() => {
            setShowAllReviewedPopup(false);
            navigate(`/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions`);
          }, 1500);
        }
      } else if (
        assignmentDetails?.activeSubmissionsQuestionRooms.includes(
          String(response.data.next_submission_id)
        )
      ) {
        return findNextUnseen();
      } else {
        // Navigate to next unseen submission
        navigate({
          pathname: `/tclasses/${props.class_id}/tassignments/${assignment_id}/tsubmissions/${response.data.next_submission_id}/tquestiondetails/${subquestion_id}`,
          search: `?${createSearchParams({
            search,
            pageNumber: scrollPageNumber,
            itemsPerPage
          })}`
        });
      }
    } catch (error) {
      console.error('Error finding next unseen submission:', error);
    }
  };

  const [isEditingEssay, setIsEditingEssay] = useState(false);
  const [editableEssayFeedback, setEditableEssayFeedback] = useState('');
  const feedbackRef = useRef(null);

  const handleEssayFeedbackChange = value => {
    setEditableEssayFeedback(value);
  };

  const handleEssayFeedbackEdit = () => {
    setEditableEssayFeedback(submissionData?.file?.filename || '');
    setIsEditingEssay(true);
  };

  const handleEssayFeedbackSave = () => {
    gradeApi
      .post({
        path: `/api/assignment/${assignment_id}/update-feedback/${submission_id}`,
        body: {
          feedback: editableEssayFeedback
        },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      })
      .catch(error => {
        console.log('Error updating feedback:', error);
      })
      .then(response => {
        if (response) {
          submissionData.file.filename = editableEssayFeedback;
          console.log(submissionData);

          setIsEditingEssay(false);

          // Optionally refetch the submission data if necessary
          getAssignmentSubmission();

          socketSubmissionUpdate();

          // console.log('Feedback updated successfully:', response.data);
        }
      });
  };

  const handleRegradeClick = subQuestionId => {
    setActiveSubQuestionId(subQuestionId);
    setIsRegradeOpen(true);

    socketApi.emit('submission-question-regrade-join', {
      submissionId: submission_id,
      id: user.user.id,
      assignmentId: assignment_id,
      questionId: subQuestionId
    });
  };

  const handleRegradeClose = () => {
    setIsRegradeOpen(false);
    setActiveSubQuestionId(null);
    socketApi.emit('submission-question-regrade-leave', {
      submissionId: submission_id,
      id: user.user.id,
      assignmentId: assignment_id,
      questionId: activeSubQuestionId
    });
    window.location.reload();
  };

  const handleRegradeSubmit = (
    newRubric,
    regradeInstructions,
    regradeFeedbackInstructions,
    solution
  ) => {
    setRegradeData({ newRubric, regradeInstructions, regradeFeedbackInstructions, solution });
    setIsConfirmationOpen(true);
  };

  const handleConfirmRegrade = async () => {
    setIsConfirmationOpen(false);
    setIsLoading(true);
    try {
      const response = await gradeApi.post({
        path: `/api/assignment/${assignment_id}/ai-regrade/`,
        body: {
          subQuestionId: activeSubQuestionId,
          newRubric: regradeData.newRubric,
          regradeInstructions: regradeData.regradeInstructions,
          regradeFeedbackInstructions: regradeData.regradeFeedbackInstructions,
          solution: regradeData.solution
        },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      // console.log('AI-Regrade initiated:', response.data);
      getQuestions(); // Fetch the updated questions
      socketApi.emit('submission-question-regrade-leave', {
        submissionId: submission_id,
        id: user.user.id,
        assignmentId: assignment_id,
        questionId: activeSubQuestionId
      });
      window.location.reload();
      setMessage('AI-Regrade initiated. The system will update the grades shortly.');
    } catch (error) {
      console.error('AI-Regrade failed:', error);
      setMessage('AI-Regrade failed. Please try again.');
    } finally {
      setIsLoading(false);
      setIsRegradeOpen(false);
      setActiveSubQuestionId(null);
    }
  };

  const SortRubricItemsAndDisplay = ({ subQuestion }) => {
    subQuestion.rubricItems.sort((a, b) => {
      if (a.value == b.value) {
        return 0;
      }
      return a.value > b.value ? -1 : 1;
    });
    return (
      <div className="rounded-xl overflow-hidden border border-gray-300">
        {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
          <div
            key={rubricIndex}
            className={`
                        flex items-center px-3 py-2 cursor-pointer
                        ${
                          hasRubricItem(subQuestion.id, rubricItem.id)
                            ? 'bg-orange-200'
                            : 'bg-white hover:bg-gray-50'
                        }
                        ${rubricIndex !== 0 ? 'border-t border-gray-300' : ''}
                    `}
            onClick={handleRubricItemChange(subQuestion.id, rubricItem)}
          >
            <div className="flex items-center flex-1">
              <div
                className={`
                            w-5 h-5 rounded-full border border-gray-400 
                            flex items-center justify-center mr-3
                            ${
                              hasRubricItem(subQuestion.id, rubricItem.id)
                                ? 'bg-gray-500 border-gray-700'
                                : ''
                            }
                        `}
              >
                {hasRubricItem(subQuestion.id, rubricItem.id) && (
                  <Check className="w-3 h-3 text-white stroke-2" />
                )}
              </div>
              <div className="flex items-center">
                <span className="text-xl font-medium mr-3">{rubricItem.value}</span>
                <span className="text-sm">{rubricItem.descriptor}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const HasSubQuestion = questionId => {
    for (const question of examPaper) {
      if (question.id !== questionId) {
        continue;
      }
      for (const subQuestion of question.subQuestions) {
        if (subQuestion.id === Number(subquestion_id)) {
          return true;
        }
      }
    }
    return false;
  };

  const getSubQuestionViewedCount = () => {
    for (const question of examPaper) {
      for (const subQuestion of question.subQuestions) {
        if (subQuestion.id === Number(subquestion_id)) {
          return subQuestion.viewed_count || 0;
        }
      }
    }
    return 0;
  };

  const getPageMapping = () => {
    getQuestionMapping();
    let num = 1;
    for (const question of examPaper) {
      for (const subQuestion of question.subQuestions) {
        if (String(subQuestion.id) === String(subquestion_id)) {
          const mappedPage = questionMapping?.[String(num)];
          setPageNumber(mappedPage); // Set the page number when we find it
          return mappedPage;
        }
        num++;
      }
    }
  };

  return (
    <div
      key={`${assignment_id}-${submission_id}-${refreshKey}`}
      className="flex flex-col mt-5 w-full overflow-x-hidden"
    >
      <style>{`
    @keyframes fade-in {
        0% {
            opacity: 0;
            transform: scale(0.95);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    .animate-fade-in {
        animation: fade-in 0.3s ease-out forwards;
    }
    .backdrop-blur-sm {
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
    }
`}</style>
      {/* navigation trail */}
      <div className="flex flex-col fixed w-full py-7 bg-white px-5">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <Link to="/tclasses" className="text-black text-lg font-medium ml-4">
              My Classes
            </Link>
            <Link to={`/tclasses/${assignment?.course_id}/tassignments`}>
              <button>
                <img src="/assets/back_arrow.png" className="size-6" alt="Back" />
              </button>
            </Link>
            <Link
              to={`/tclasses/${assignment?.course_id}/tassignments`}
              className="text-black text-lg font-medium ml-4"
            >
              {assignment?.course?.name.length > 20
                ? assignment?.course?.name.substring(0, 17) + '...'
                : assignment?.course?.name}
            </Link>
            <Link
              to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
            >
              <button>
                <img src="/assets/back_arrow.png" className="size-6" alt="Back" />
              </button>
            </Link>
            <Link
              to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
              className="text-black text-lg font-medium ml-4"
            >
              {assignment?.title.length > 20
                ? assignment?.title.substring(0, 17) + '...'
                : assignment?.title}
            </Link>
            <Link
              to={`/tclasses/${assignment?.course_id}/tassignments/${assignment?.id}/tsubmissions`}
            >
              <img src="/assets/back_arrow.png" className="size-6" alt="Back" />
            </Link>
            <h1 className="text-black text-lg font-medium ml-4">
              {studentName.length > 15 ? studentName.substring(0, 12) + '...' : studentName}
            </h1>
          </div>

          {isReloadMessageShown && (
            <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
              Change detected, reloading...
            </div>
          )}

          <div className="flex items-center justify-between">
            {teachers &&
              teachers.entities?.length > 0 &&
              (teachers?.entities?.length <= 3 ? (
                (teachers?.entities ?? []).map(t => (
                  <div key={t.id} className="relative flex items-center space-x-2 ml-2">
                    <span
                      id={`teacher${t.id}`}
                      className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 
                            flex items-center justify-center p-4 ${t.id === user.user.id ? 'border-orange-200' : 'border-gray-400'}`}
                    >
                      {t.fullName[0].toUpperCase()}
                    </span>
                    <Tooltip anchorSelect={`#teacher${t.id}`} place="bottom">
                      {t.fullName}
                    </Tooltip>
                  </div>
                ))
              ) : (
                <>
                  {(teachers?.entities?.slice(0, 3) ?? []).map(t => (
                    <div key={t.id} className="relative flex items-center space-x-2 ml-2">
                      <span
                        id={`teacher${t.id}`}
                        className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 
                            flex items-center justify-center p-4 ${t.id === user.user.id ? 'border-orange-200' : 'border-gray-400'}`}
                      >
                        {t.fullName[0].toUpperCase()}
                      </span>
                      <Tooltip anchorSelect={`#teacher${t.id}`} place="bottom">
                        {t.fullName}
                      </Tooltip>
                    </div>
                  ))}
                  <div className="relative flex items-center space-x-2 ml-2">
                    <span
                      id="teachers-hidden"
                      className={`relative cursor-pointer text-lg w-7 h-7 rounded-full border border-gray-400 flex items-center justify-center p-4`}
                    >
                      ...
                    </span>
                    <Tooltip anchorSelect="#teachers-hidden" place="bottom">
                      {(teachers?.entities?.slice(3) ?? []).map(t => (
                        <div key={t.id} className="flex gap-2 items-center justify-center">
                          <p>{t.fullName}</p>
                          {t.role === 'owner' ? (
                            <span className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-red-100 text-red-800">
                              I
                            </span>
                          ) : (
                            <span className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-green-100 text-green-800">
                              C
                            </span>
                          )}
                        </div>
                      ))}
                    </Tooltip>
                  </div>
                </>
              ))}
            <div className="flex items-center space-x-2 ml-4">
              <div className="flex items-center space-x-2 mr-5">
                <div className="text text-gray-600 mr-10">
                  {viewedCount}/{assignment?.num_submissions || 0} manually reviewed
                </div>
                <button
                  className="text-gray-600 text-l font-medium flex items-center"
                  onClick={clickRight}
                >
                  <span>Back</span>
                  <i className="fas fa-sort-up -rotate-90 text-2xl ml-3"></i>
                </button>
                <button
                  className="text-gray-600 text-l font-medium flex items-center"
                  onClick={clickLeft}
                >
                  <i className="fas fa-sort-up rotate-90 text-2xl mr-2"></i>
                  <span>Next</span>
                </button>
              </div>

              <div className="flex items-center space-x-4">
                <button
                  className="text-gray-600 text-md hover:bg-gray-200 hover:text-gray-800 font-medium flex items-center border border-black rounded-full p-2"
                  onClick={findNextSubquestion}
                >
                  <span>{`Next ${assignment?.type === 'flexible_problem_set' ? 'Question' : 'Sub Question'}`}</span>
                  <div className="ml-2 flex flex-row transform rotate-90">
                    <i className="fas fa-play text-sm"></i>
                  </div>
                </button>
                <button
                  className="text-gray-600 text-l hover:bg-gray-200 hover:text-gray-800 font-medium flex items-center border border-black rounded-full p-2"
                  onClick={findNextUnseen}
                >
                  <span>Next Unseen</span>
                  <div className="ml-2 flex flex-row">
                    <i className="fas fa-play text-sm"></i>
                    <i className="fas fa-play text-sm"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main content */}
      <div className="flex h-[calc(100vh-9rem)] mt-[7rem]">
        {/* student work */}
        <div className="w-1/2 p-4">
          {submissionData?.submitted &&
            (submissionData.essay_text ? (
              <div className="w-full h-full overflow-auto bg-white border border-gray-300 p-4 rounded-xl">
                <pre className="whitespace-pre-wrap font-sans">{submissionData.essay_text}</pre>
              </div>
            ) : (
              <div className="ml-5 mr-5 w-1/2 p-4 h-[calc(100vh-10rem)] min-w-full border border-gray-300 rounded-xl">
                {' '}
                {/* Added min-width */}
                {submissionData?.submitted &&
                  (submissionData.essay_text ? (
                    <div className="w-full h-full overflow-auto bg-white p-4">
                      <pre className="whitespace-pre-wrap font-sans">
                        {submissionData.essay_text}
                      </pre>
                    </div>
                  ) : submissionData.assignment?.type === 'flexible_problem_set' ? (
                    <div className="bg-white h-full border border-gray-200 rounded-lg shadow-sm p-6 overflow-auto mt-[10px]">
                      <SUnpublishedFlexibleAssignment
                        isReadMode
                        assignment_id={submissionData.assignment.id}
                        assignment={submissionData.assignment}
                        class_id={props.class_id}
                        studentId={submissionData.user.id}
                        questionId={subquestion_id}
                      />
                    </div>
                  ) : (
                    <div className="w-full h-full relative flex-1">
                      {' '}
                      {/* Added flex-1 */}
                      <PDFView
                        url={`${apiUrl}/api/pdf-file/${submissionData.file.filename}`}
                        className="absolute inset-0 flex-1" /* Added flex-1 */
                        initialPage={pageNumber}
                      />
                    </div>
                  ))}
              </div>
            ))}
        </div>

        <div className="w-1/2 overflow-y-auto p-8">
          {assignment?.is_exam && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Assigned to:</label>
              <StudentSearchDropdown
                search={search}
                isLoading={isAssignmentStudentsLoading}
                pageNumber={Number(scrollPageNumber)}
                students={students}
                currentStudentName={studentName}
                updateSearchParams={updateSearchParams}
                onStudentSelect={student => {
                  setNewStudentAssignment(student);
                  setIsStudentAssignmentConfirmationOpen(true);
                }}
              />
              {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
            </div>
          )}
          {groupStudents.length > 0 && (
            <div>
              <p>Group Members:</p>
              <p>
                {Array.isArray(groupStudents) && groupStudents.length > 0
                  ? groupStudents.map((student, index) => (
                      <span key={index}>
                        {student[0]} ({student[1]}){index < groupStudents.length - 1 ? ', ' : ''}
                      </span>
                    ))
                  : 'No students to display'}
              </p>
            </div>
          )}
          {examPaper.map((question, questionIndex) => {
            return HasSubQuestion(question.id) ? (
              <div key={questionIndex} className="mb-8">
                {question.subQuestions.map((subQuestion, subQuestionIndex) => {
                  return subQuestion.id == subquestion_id ? (
                    <div
                      key={subQuestionIndex}
                      className={`mb-6 ml-4 ${regradeInProgress[subQuestion.id] ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''}`}
                    >
                      {regradeInProgress[subQuestion.id] && (
                        <div className="mt-2 mb-4 text-xl text-orange-300">
                          This subquestion is being regraded...
                        </div>
                      )}
                      <>
                        <h2 className="text-xl font-bold mb-4">{`Question ${questionIndex + 1}:`}</h2>
                        {!question.is_without_sub_questions && (
                          <h3 className="font-semibold mb-2">
                            {`${String.fromCharCode(97 + subQuestionIndex)}) ${subQuestion.question}`}
                          </h3>
                        )}
                      </>

                      <div className="flex flex-col gap-2 mb-2 mt-3">
                        <SortRubricItemsAndDisplay subQuestion={subQuestion} />
                      </div>
                      {
                        <div className="mt-2">
                          {isEditing(subQuestion.id) ? (
                            <>
                              <textarea
                                ref={el => (feedbackRefs.current[subQuestion.id] = el)}
                                value={editableFeedback[subQuestion.id]}
                                className="w-full p-2 border rounded"
                                rows="3"
                                onChange={e => handleFeedbackChange(subQuestion.id, e.target.value)}
                              />
                              <div className="flex justify-between items-center mt-2">
                                <button
                                  className="less-sexy-button w-40 h-9 hover:bg-gray-200"
                                  onClick={() => handleFeedbackSave(subQuestion.id)}
                                >
                                  Save
                                </button>
                                {subQuestion.questionType !== 'mcq' && (
                                  <button
                                    className="less-sexy-button w-50 h-9 hover:bg-gray-200 flex items-center justify-center"
                                    onClick={() => handleRegradeClick(subQuestion.id)}
                                  >
                                    <span className="mr-2 text-2xl" style={{ fontSize: '1.5em' }}>
                                      ✨
                                    </span>
                                    <span>Fine Tune Grading</span>
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {renderFeedback(getFeedback(subQuestion.id))}
                              <div className="flex justify-between items-center mt-2">
                                <button
                                  className="less-sexy-button w-40 h-9 hover:bg-gray-200"
                                  onClick={() => handleFeedbackEdit(subQuestion.id)}
                                >
                                  Edit
                                </button>
                                {subQuestion.questionType !== 'mcq' && (
                                  <button
                                    disabled={getIsDisabledGradingButton(subQuestion.id)}
                                    className={`less-sexy-button w-50 h-9 hover:bg-gray-200 flex items-center justify-center  ${getIsDisabledGradingButton(subQuestion.id) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                    onClick={() => handleRegradeClick(subQuestion.id)}
                                  >
                                    <span className="mr-2 text-2xl" style={{ fontSize: '1.5em' }}>
                                      ✨
                                    </span>
                                    <span>Fine Tune Grading</span>
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      }

                      {getRR(subQuestion.id) && (
                        <div className="mt-4 bg-orange-100 p-4 rounded">
                          <h4 className="font-semibold mb-2">Regrade Request:</h4>
                          <p>{getRRText(subQuestion.id)}</p>
                          <button
                            className="less-sexy-button w-full h-9 hover:bg-gray-200 mt-2"
                            onClick={() => handleRegradeReq(subQuestion.id)}
                          >
                            Dismiss Regrade Request
                          </button>
                        </div>
                      )}
                    </div>
                  ) : null;
                })}
              </div>
            ) : null;
          })}
          {assignment?.type === 'essay' && (
            <div className="w-full bg-white border border-gray-300 p-4 rounded-xl">
              <style>{`
                    .feedback-content h2 {
                        font-size: 20px;
                        font-weight: var(--h2-font-weight);
                        margin: var(--h2-margin);
                    }
                    .feedback-content p {
                        margin: var(--p-margin);
                    }
                    .feedback-content ul {
                        list-style-type: disc;
                        padding: var(--ul-padding);
                        margin: var(--ul-margin);
                    }
                    .feedback-content li {
                        margin: var(--li-margin);
                    }
                `}</style>
              {isEditingEssay ? (
                <>
                  <div className="">
                    <textarea
                      ref={feedbackRef}
                      value={editableEssayFeedback}
                      className="w-full p-2 border-none h-[500px]"
                      rows="10"
                      onChange={e => handleEssayFeedbackChange(e.target.value)}
                    />
                    <button
                      className="less-sexy-button w-40 h-9 hover:bg-gray-200 mt-2"
                      onClick={handleEssayFeedbackSave}
                    >
                      Save
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col justify-between feedback-content">
                    <div
                      dangerouslySetInnerHTML={{ __html: submissionData?.file?.filename }}
                      className="feedback-content"
                      style={{
                        '--h2-font-size': '1.5em',
                        '--h2-font-weight': 'bold',
                        '--h2-margin': '1em 0 0.5em',
                        '--p-margin': '0 0 1em',
                        '--ul-padding': '0 0 0 2em',
                        '--ul-margin': '0 0 1em',
                        '--li-margin': '0 0 0.5em',
                        height: 'auto', // Adjust the height property
                        overflow: 'visible' // Ensure overflow is visible
                      }}
                    />
                    <button
                      className="less-sexy-button w-40 h-9 hover:bg-gray-200 mt-2"
                      onClick={handleEssayFeedbackEdit}
                    >
                      Edit
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {showAllReviewedPopup && (
        <>
          {/* Blurred background overlay */}
          <div
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50"
            style={{ backdropFilter: 'blur(4px)' }}
          />

          {/* Popup */}
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transform transition-all animate-fade-in">
              <div className="flex items-center">
                <div className="bg-orange-100 rounded-full p-3 mr-4">
                  <img src="/assets/checkmark.png" className="size-6" alt="Success" />
                </div>
                <p className="text-lg font-medium">
                  All submissions for this subquestion reviewed. Taking you to the next subquestion.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {showNextSubquestionPopup && (
        <>
          {/* Blurred background overlay */}
          <div
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50"
            style={{ backdropFilter: 'blur(4px)' }}
          />

          {/* Popup */}
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transform transition-all animate-fade-in">
              <div className="flex items-center">
                <div className="bg-orange-100 rounded-full p-3 mr-4">
                  <img src="/assets/checkmark.png" className="size-6" alt="Success" />
                </div>
                <p className="text-lg font-medium">
                  {nextSubQuestionId
                    ? `Taking you to the next ${assignment?.type === 'flexible_problem_set' ? 'question' : 'subquestion'}...`
                    : `No more ${assignment?.type === 'flexible_problem_set' ? 'questions' : 'subquestions'}. Taking you to the assignment page...`}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {showConnectionErrorPopup && (
        <>
          <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded">
            Connection error, reconnecting ...
          </div>
        </>
      )}

      {isRegradeOpen && (
        <AIRegrade
          subQuestion={examPaper
            .flatMap(q => q.subQuestions)
            .find(sq => sq.id === activeSubQuestionId)}
          isEssay={assignment?.type === 'essay'}
          onClose={handleRegradeClose}
          onSubmit={handleRegradeSubmit}
        />
      )}

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        message="Are you sure you want to regrade this subquestion for all students with the new rubric and grading instructions?"
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmRegrade}
      >
        <p>This action cannot be undone.</p>
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isStudentAssignmentConfirmationOpen}
        message={`Assigning to ${newStudentAssignment?.name} (ID: ${newStudentAssignment?.id}) will unassign any submission that may be currently assigned to them.`}
        onClose={() => setIsStudentAssignmentConfirmationOpen(false)}
        onConfirm={handleConfirmStudentAssignment}
      >
        <p>Are you sure you want to proceed?</p>
      </ConfirmationModal>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <p>Regrading in progress...</p>
            <p>Expected wait time: 30 seconds</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TQuestionDetails;
