import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { gradeApi } from '../../../api.js';
import { useAuth } from '../../../auth/AuthContext';
import { getFieldName } from '../TPset/utils.js';
import { TQuestionType } from './TQuestionType';

export const TFlexibleAssignmentSubQuestion = ({
  question,
  subQuestion,
  isPublished,
  index,
  fieldName,
  disabledStyle,
  setError,
  deleteSubQuestion,
  viewedQuestions,
  setViewedQuestions,
  copiedRubricItems,
  onCopyRubricItems,
  onPasteRubricItems
}) => {
  const isSubQuestionFocused = viewedQuestions.get(question.id)?.includes(subQuestion?.id);

  const { user } = useAuth();
  const { values, setFieldValue, submitForm } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showAIRubricModal, setShowAIRubricModal] = useState(false);
  const [maxPoints, setMaxPoints] = useState('');
  const [aiRubricError, setAiRubricError] = useState('');

  const { isDragging, setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: subQuestion.id,
    animateLayoutChanges: () => false
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  useEffect(() => {
    if (isDragging) {
      if (isSubQuestionFocused) {
        setViewedQuestions(prev => new Map([...prev, [question.id, []]]));
      }
    }
  }, [isDragging]);

  const handleVisualSolutionUpload = async event => {
    if (!event.target.files?.[0]) {
      return;
    }

    setIsLoading(true); // Use same loading state
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const base64Image = reader.result.split(',')[1];
        const mainQuestion = values.examPaper[question.id - 1].question;
        const subQuestionText =
          values.examPaper[question.id - 1].subQuestions[subQuestion.id - 1].question;
        const fullQuestion = `${mainQuestion}\n${String.fromCharCode(96 + subQuestion.id)}) ${subQuestionText}`;

        const response = await gradeApi.post({
          path: '/api/assignment/process-visual-solution/',
          body: {
            question: fullQuestion,
            image: base64Image
          },
          config: {
            headers: { Authorization: `Bearer ${user.session_token}` }
          }
        });

        const { annotation } = response.data;
        setFieldValue(
          `examPaper[${question.id - 1}].subQuestions[${subQuestion.id - 1}].solution`,
          annotation
        );
        await submitForm();
      } catch (error) {
        setError('Failed to process image. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleImageUpload = async event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageUrl = reader.result;
        setFieldValue(getFieldName(index, 'imageUrl', fieldName), imageUrl);
        setFieldValue(getFieldName(index, 'imageS3Key', fieldName), null);
      };
      reader.readAsDataURL(file);

      setTimeout(async () => {
        await submitForm();
      }, 500);
    }
  };

  const handleAIRubricClick = () => {
    if (!subQuestion.solution) {
      setAiRubricError('Please enter the solution before generating an AI rubric.');
      return;
    }
    setShowAIRubricModal(true);
  };

  const generateAIRubric = async (questionIndex, subQuestionIndex, maxPoints) => {
    setIsLoading(true);
    try {
      const response = await gradeApi.post({
        path: '/api/assignment/ai-rubric/',
        body: {
          question: values.examPaper[questionIndex].description,
          subQuestion: values.examPaper[questionIndex].subQuestions[subQuestionIndex].question,
          solution: values.examPaper[questionIndex].subQuestions[subQuestionIndex].solution,
          maxPoints: maxPoints
        },
        config: {
          headers: { Authorization: `Bearer ${user.session_token}` }
        }
      });

      const newRubricItems = response.data.rubricItems.map(item => ({
        descriptor: item.description,
        value: item.points.toString()
      }));

      setFieldValue(
        `examPaper[${questionIndex}].subQuestions[${subQuestionIndex}].rubricItems`,
        newRubricItems
      );

      setTimeout(async () => {
        await submitForm();
      }, 500);
    } catch (error) {
      console.log(error);
      setError('Failed to generate AI rubric. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateAIRubric = () => {
    const numericMaxPoints = parseInt(maxPoints, 10);
    if (isNaN(numericMaxPoints) || numericMaxPoints <= 0) {
      setError('Please enter a valid positive number for maximum points.');
      return;
    }
    setShowAIRubricModal(false);
    generateAIRubric(question.id - 1, subQuestion.id - 1, numericMaxPoints);
  };

  const updateSubViewedQuestions = id => {
    const expandedQ = new Map(viewedQuestions);
    const selectedQuestionKeys = viewedQuestions.get(question.id);
    if (isSubQuestionFocused) {
      expandedQ.set(question.id, [...selectedQuestionKeys.filter(i => i !== id)]);
    } else {
      expandedQ.set(question.id, [...selectedQuestionKeys, id]);
    }

    return setViewedQuestions(expandedQ);
  };

  return (
    <div ref={setNodeRef} style={style} className="rounded-xl bg-white p-4 mb-4 w-full">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center space-x-4">
          <div
            className={`size-10 flex items-center justify-center ${disabledStyle}`}
            style={{ marginLeft: 0 }}
          >
            <button type="button" onClick={() => updateSubViewedQuestions(subQuestion.id)}>
              <img
                src={
                  isSubQuestionFocused
                    ? '/assets/arrow-up-white.png'
                    : '/assets/arrow-down-white.png'
                }
                className="size-10"
              />
            </button>
          </div>

          <div className="font-bold flex items-center text-base">
            Sub-question {String.fromCharCode(96 + (index + 1))}
          </div>
          <Field
            as="select"
            name={getFieldName(index, 'questionType', fieldName)}
            disabled={isPublished}
            className="border rounded-xl py-1"
            onBlur={submitForm}
          >
            <option value="image">Image upload</option>
            <option value="text">Text response</option>
            <option value="mcq">Multiple Choice Question</option>
          </Field>
        </div>
        <div className="flex items-center space-x-4">
          <div className="size-10 flex items-center justify-center">
            <button
              type="button"
              {...(isPublished ? {} : { ...listeners, ...attributes })}
              disabled={isPublished}
            >
              <img src="/assets/list.png" className="size-10" />
            </button>
          </div>
          <div className={`size-4 flex items-center justify-center ${disabledStyle}`}>
            <button
              type="button"
              disabled={isPublished}
              onClick={async () => {
                await deleteSubQuestion(index);
                submitForm();
              }}
            >
              <img src="/assets/trash.png" className="size-4" alt="Delete sub-question" />
            </button>
          </div>
        </div>
      </div>

      {showAIRubricModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">AI Rubric</h3>
            <p className="mb-4">
              Enter question weight (maximum number of points for this question):
            </p>
            <input
              type="number"
              value={maxPoints}
              className="border rounded px-2 py-1 mb-4 w-full"
              onChange={e => setMaxPoints(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleGenerateAIRubric();
                }
              }}
            />
            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                className="less-sexy-button"
                onClick={() => setShowAIRubricModal(false)}
              >
                Cancel
              </button>
              <button type="button" className="sexy-button" onClick={handleGenerateAIRubric}>
                Generate
              </button>
            </div>
          </div>
        </div>
      )}

      {isSubQuestionFocused && (
        <>
          <TQuestionType
            index={index}
            mcqCheckboxId={`checkbox-${question.id}-${subQuestion.id}`}
            disabledStyle={disabledStyle}
            isLoading={isLoading}
            fieldName={fieldName}
            isPublished={isPublished}
            rubricQuestionType={
              subQuestion.questionType === 'text' || subQuestion.questionType === 'image'
            }
            imageS3Key={subQuestion.imageS3Key}
            rubricItems={subQuestion.rubricItems}
            mcqOptions={subQuestion.mcqOptions}
            copiedRubricItems={copiedRubricItems}
            handleImageUpload={handleImageUpload}
            submitForm={submitForm}
            setFieldValue={setFieldValue}
            handleAIRubricClick={handleAIRubricClick}
            aiRubricError={aiRubricError}
            handleVisualSolutionUpload={handleVisualSolutionUpload}
            onCopyRubricItems={onCopyRubricItems}
            onPasteRubricItems={() => onPasteRubricItems(question.id - 1, subQuestion.id - 1)}
          />
        </>
      )}
    </div>
  );
};
