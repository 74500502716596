import { DndContext, rectIntersection } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Field, useFormikContext } from 'formik';
import { gradeApi } from '../../../api.js';
import { useAuth } from '../../../auth/AuthContext.js';

import { isEmpty } from 'lodash';

import React, { useEffect, useState } from 'react';
import { getFieldName, updateIds } from '../TPset/utils.js';
import { TFlexibleAssignmentSubQuestionColumn } from './TFlexibleAssignmentSubQuestionColumn';
import { TQuestionType } from './TQuestionType';

export const TFlexibleAssignmentQuestion = ({
  fieldName,
  examPaper,
  question,
  isPublished,
  deleteQuestion,
  index,
  disabledStyle,
  viewedQuestions,
  setViewedQuestions,
  setError,
  updateViewedQuestions,
  handleChange,
  copiedRubricItems,
  onCopyRubricItems,
  onPasteRubricItems
}) => {
  const { user } = useAuth();

  const isQuestionFocused = viewedQuestions.has(question.id);
  const { setFieldValue, submitForm, values } = useFormikContext();
  const [aiRubricError, setAiRubricError] = useState('');
  const [showAIRubricModal, setShowAIRubricModal] = useState(false);
  const [maxPoints, setMaxPoints] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { isDragging, setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: question.id,
    animateLayoutChanges: () => false
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  useEffect(() => {
    if (isDragging) {
      if (isQuestionFocused) {
        setViewedQuestions(prev => new Map(prev.clear()));
      }
    }
  }, [isDragging]);

  const getTaskPos = (id, subQuestions) =>
    subQuestions.findIndex(subQuestion => subQuestion.id === id);

  const handleDragEnd = event => {
    console.log(question);
    const { active, over } = event;

    if (!active || !over || !active.id || !over.id || active.id === over.id) {
      return;
    }

    if (!question) {
      return;
    }

    const originalPos = getTaskPos(active.id, question.subQuestions);
    const newPos = getTaskPos(over.id, question.subQuestions);

    const updatedSubQuestions = arrayMove(question.subQuestions, originalPos, newPos);

    const newExamPaper = examPaper.map(q => {
      if (q.id === question.id) {
        return {
          ...q,
          subQuestions: updateIds(updatedSubQuestions)
        };
      }

      return q;
    });
    handleChange({ value: newExamPaper, fieldName: 'examPaper' });
  };

  const handleImageUpload = async event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageUrl = reader.result;
        setFieldValue(getFieldName(index, 'imageUrl', fieldName), imageUrl);
        setFieldValue(getFieldName(index, 'imageS3Key', fieldName), null);

        setTimeout(async () => {
          await submitForm();
        }, 500);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAIRubricClick = () => {
    if (question.subQuestions.length < 0 && !question.solution) {
      setAiRubricError('Please enter the solution before generating an AI rubric.');
      return;
    }
    setShowAIRubricModal(true);
  };

  const generateAIRubric = async (index, maxPoints) => {
    setIsLoading(true);
    try {
      const response = await gradeApi.post({
        path: '/api/assignment/ai-rubric/',
        body: {
          question: values.examPaper[index].question,
          subQuestion: values.examPaper[index].question,
          solution: values.examPaper[index].solution,
          maxPoints: maxPoints
        },
        config: {
          headers: { Authorization: `Bearer ${user.session_token}` }
        }
      });

      const newRubricItems = response.data.rubricItems.map(item => ({
        descriptor: item.description,
        value: item.points.toString()
      }));

      setFieldValue(getFieldName(index, 'rubricItems', fieldName), newRubricItems);

      setTimeout(async () => {
        await submitForm();
      }, 500);
    } catch (error) {
      console.log(error);
      setError('Failed to generate AI rubric. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVisualSolutionUpload = async event => {
    if (!event.target.files?.[0]) {
      return;
    }

    setIsLoading(true); // Use same loading state
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const base64Image = reader.result.split(',')[1];
        const fullQuestion = values.examPaper[question.id - 1].question;

        const response = await gradeApi.post({
          path: '/api/assignment/process-visual-solution/',
          body: {
            question: fullQuestion,
            image: base64Image
          },
          config: {
            headers: { Authorization: `Bearer ${user.session_token}` }
          }
        });

        const { annotation } = response.data;
        setFieldValue(`examPaper[${question.id - 1}].solution`, annotation);
        await submitForm();
      } catch (error) {
        setError('Failed to process image. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleGenerateAIRubric = () => {
    const numericMaxPoints = parseInt(maxPoints, 10);
    if (isNaN(numericMaxPoints) || numericMaxPoints <= 0) {
      setError('Please enter a valid positive number for maximum points.');
      return;
    }
    setShowAIRubricModal(false);
    generateAIRubric(index, numericMaxPoints);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`rounded-3xl p-4 mb-4 bg-gray-50 shadow-sm ${disabledStyle}`}
    >
      {isPublished && <div className="relative inset-0 bg-opacity-10 z-10" />}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className={`size-10 flex items-center justify-center `}>
            <button type="button" onClick={() => updateViewedQuestions(question.id)}>
              <img
                src={isQuestionFocused ? '/assets/arrow-up.png' : '/assets/arrow-down.png'}
                className="size-10"
              />
            </button>
          </div>
          <div className="font-bold flex items-center text-base">Question {index + 1}</div>
          {question.subQuestions.length < 1 && (
            <Field
              as="select"
              name={getFieldName(index, 'questionType', fieldName)}
              disabled={isPublished}
              className="border rounded-xl py-1"
              onBlur={submitForm}
            >
              <option value="image">Image upload</option>
              <option value="text">Text response</option>
              <option value="mcq">Multiple Choice Question</option>
            </Field>
          )}
        </div>
        <div className="flex items-center space-x-0">
          <div className={`size-10 flex items-center justify-center ${disabledStyle} `}>
            <button
              type="button"
              {...(isPublished ? {} : { ...listeners, ...attributes })}
              disabled={isPublished}
            >
              <img src="/assets/list.png" className="size-10" />
            </button>
          </div>
          <div className="size-4 flex items-center justify-center">
            <button
              type="button"
              disabled={isPublished || examPaper.length < 2}
              onClick={() => {
                deleteQuestion(index);
                setTimeout(async () => {
                  await submitForm();
                }, 500);
              }}
            >
              <img src="/assets/trash.png" className="size-4" alt="Delete question" />
            </button>
          </div>
        </div>
      </div>

      {showAIRubricModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">AI Rubric</h3>
            <p className="mb-4">
              Enter question weight (maximum number of points for this question):
            </p>
            <input
              type="number"
              value={maxPoints}
              className="border rounded px-2 py-1 mb-4 w-full"
              onChange={e => setMaxPoints(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleGenerateAIRubric();
                }
              }}
            />
            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                className="less-sexy-button"
                onClick={() => setShowAIRubricModal(false)}
              >
                Cancel
              </button>
              <button type="button" className="sexy-button" onClick={handleGenerateAIRubric}>
                Generate
              </button>
            </div>
          </div>
        </div>
      )}

      {isQuestionFocused && (
        <>
          <TQuestionType
            index={index}
            isDisabled={question.subQuestions.length > 0}
            isLoading={isLoading}
            mcqCheckboxId={`checkbox-${question.id}`}
            disabledStyle={disabledStyle}
            fieldName={fieldName}
            isPublished={isPublished}
            rubricQuestionType={
              question.questionType === 'text' || question.questionType === 'image'
            }
            imageS3Key={question.imageS3Key}
            rubricItems={question.rubricItems}
            mcqOptions={question.mcqOptions}
            copiedRubricItems={copiedRubricItems}
            aiRubricError={aiRubricError}
            handleImageUpload={handleImageUpload}
            submitForm={submitForm}
            handleAIRubricClick={handleAIRubricClick}
            setFieldValue={setFieldValue}
            handleVisualSolutionUpload={handleVisualSolutionUpload}
            onCopyRubricItems={onCopyRubricItems}
            onPasteRubricItems={() => onPasteRubricItems(question.id - 1)}
          />
          {question.subQuestions.length >= 1 && (
            <div className="flex w-full mt-2 mb-4 pr-[25px] justify-end">
              {!isEmpty(viewedQuestions.get(question.id)) ? (
                <button
                  type="button"
                  className="self-end "
                  onClick={() => {
                    setViewedQuestions(prev => new Map([...prev, [question.id, []]]));
                  }}
                >
                  <img src="/assets/hide.png" className="size-6" alt="Hide all" />
                </button>
              ) : (
                <button
                  type="button"
                  className="self-end"
                  onClick={() => {
                    const allSubIds = question.subQuestions.map(subq => subq.id);
                    setViewedQuestions(prev => new Map([...prev, [question.id, allSubIds]]));
                  }}
                >
                  <img src="/assets/view.png" className="size-6" alt="View all" />
                </button>
              )}
            </div>
          )}
          <DndContext
            collisionDetection={rectIntersection}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
          >
            <TFlexibleAssignmentSubQuestionColumn
              question={question}
              questionIndex={index}
              subQuestions={question.subQuestions}
              isPublished={isPublished}
              questionFieldName={fieldName}
              fieldName={getFieldName(index, 'subQuestions', fieldName)}
              disabledStyle={disabledStyle}
              setError={setError}
              setViewedQuestions={setViewedQuestions}
              viewedQuestions={viewedQuestions}
              copiedRubricItems={copiedRubricItems}
              onCopyRubricItems={onCopyRubricItems}
              onPasteRubricItems={onPasteRubricItems}
            />
          </DndContext>
        </>
      )}
    </div>
  );
};
