import React, {useEffect, useState} from 'react';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import {
    LineChart,
    BarChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import WaitlistSection from './WaitlistSection';

function ADashboard() {
    const [pricingSettings, setPricingSettings] = useState({
        availablePrices: [], // Will be populated from backend
        freeTrialAssignments: 2
    });


    const [allStats, setAllStats] = useState({
        global: null,
        weekly: [],
        teachers: [],
        classDetails: new Map(), // Store detailed class info
        assignmentDetails: new Map() // Store detailed assignment info
    });
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const {user} = useAuth();

    useEffect(() => {
        fetchAndProcessData();
    }, []);

    const getAssignmentDate = (assignment) => {
        // If there are submissions, use the date of first submission
        if (assignment.submissions && assignment.submissions.length > 0) {
            const firstSubmission = [...assignment.submissions]
                .sort((a, b) => new Date(a.submittedDate) - new Date(b.submittedDate))[0];
            return firstSubmission.submittedDate;
        }

        // If no submissions, exclude from stats
        return null;
    };

    const fetchAndProcessData = async () => {
        try {
            // Get teachers
            const teachersResponse = await gradeApi.get({
                path: '/api/teachers/',
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });

            // Get stats for each teacher
            const teacherStatsPromises = teachersResponse.data.map(teacher =>
                gradeApi.get({
                    path: `/api/teachers/${teacher.id}/statistic/`,
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                })
            );

            const teacherStatsResponses = await Promise.all(teacherStatsPromises);

            // Process everything
            processAllData(teachersResponse.data, teacherStatsResponses.map(r => r.data));

        } catch (error) {
            setErrorMessage('Failed to fetch data: ' + error.message);
        }
    };


    const PricingSettingsCard = () => (
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Pricing Settings</h2>
            <div className="space-y-4">
                <div>
                    <label className="block mb-2">Free Trial Assignments</label>
                    <input
                        type="number"
                        min="0"
                        value={pricingSettings.freeTrialAssignments}
                        className="w-full p-2 border rounded"
                        onChange={async (e) => {
                            const newValue = parseInt(e.target.value);
                            try {
                                await gradeApi.put({
                                    path: '/api/pricing/settings',
                                    data: {freeTrialAssignments: newValue},
                                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                                });
                                setPricingSettings({
                                    ...pricingSettings,
                                    freeTrialAssignments: newValue
                                });
                            } catch (error) {
                                setErrorMessage('Failed to update free trial settings: ' + error.message);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );

    const getWeekStart = (dateStr) => {
        // First check if we have a valid date string
        if (!dateStr) {
            return new Date(); // Return current date if no date provided
        }

        try {
            const d = new Date(dateStr);

            // Check if date is valid
            if (isNaN(d.getTime())) {
                console.warn(`Invalid date string: ${dateStr}`);
                return new Date(); // Return current date if invalid
            }

            d.setHours(0, 0, 0, 0);
            d.setDate(d.getDate() - d.getDay());
            return d;
        } catch (error) {
            console.warn(`Error processing date: ${dateStr}`, error);
            return new Date(); // Return current date if error
        }
    };

    const calculateMIR = (manualRegradeCount, subQuestionCount, gradedSubmissions) => {
        if (subQuestionCount === 0 || gradedSubmissions === 0) {
            return 0;
        }
        return (manualRegradeCount / (subQuestionCount * gradedSubmissions)) * 100;
    };

    const
        processAllData = (teachers, teacherStats) => {
            let allAssignmentMIRs = [];
            const weeklyBuckets = new Map();
            const classDetailsMap = new Map();
            const assignmentDetailsMap = new Map();
            let globalTotals = {
                totalAssignments: 0,
                publishedAssignments: 0,
                totalSubmissions: 0,
                gradedSubmissions: 0,
                totalManualRegrades: 0,
                totalAiRegrades: 0,
                totalSubQuestions: 0
            };

            // Process teacher stats and build weekly buckets
            const processedTeachers = teachers.map((teacher, index) => {
                const stats = teacherStats[index];
                const teacherData = {
                    ...teacher,
                    classes: [], // Store class details
                    assignmentCount: 0,
                    publishedCount: 0,
                    submissionCount: 0,
                    gradedSubmissions: 0,
                    manualRegradeCount: 0,
                    aiRegradeCount: 0,
                    subQuestionCount: 0,
                    weeklyActivity: new Map()
                };

                // Process each class and its assignments
                stats.forEach(classStat => {
                    classDetailsMap.set(classStat.id, classStat);
                    teacherData.classes.push(classStat);

                    classStat.assignments?.forEach(assignment => {
                        const assignmentDate = getAssignmentDate(assignment);

                        // Skip exams with no submissions
                        if (assignmentDate === null) {
                            return;
                        }

                        assignmentDetailsMap.set(assignment.id, assignment);

                        const weekStart = getWeekStart(assignmentDate);
                        const weekKey = weekStart.toISOString().split('T')[0];


                        // Update weekly buckets
                        if (!weeklyBuckets.has(weekKey)) {
                            weeklyBuckets.set(weekKey, {
                                weekStart: weekKey,
                                assignmentsCreated: 0,
                                assignmentsPublished: 0,
                                submissionsGraded: 0,
                                aiRegrades: 0,
                                manualRegrades: 0,
                                weeklyMIR: 0
                            });
                        }

                        // Update all the counters
                        const weekBucket = weeklyBuckets.get(weekKey);
                        weekBucket.assignmentsCreated++;
                        if (assignment.isPublished) {
                            weekBucket.assignmentsPublished++;
                        }
                        weekBucket.submissionsGraded += assignment.gradedSubmissionsCount;
                        weekBucket.aiRegrades += assignment.aiRegradeCount;
                        weekBucket.manualRegrades += assignment.manualRegradeCount || 0;

                        // Calculate weekly MIR
                        weekBucket.weeklyMIR = calculateMIR(
                            weekBucket.manualRegrades,
                            assignment.subQuestionCount,
                            weekBucket.submissionsGraded
                        );

                        const assignmentMIR = calculateMIR(
                            assignment.manualRegradeCount,
                            assignment.subQuestionCount,
                            assignment.gradedSubmissionsCount
                        );
                        allAssignmentMIRs.push(assignmentMIR);
                        // Update teacher stats

                        teacherData.assignmentCount++;
                        teacherData.publishedCount += assignment.isPublished ? 1 : 0;
                        teacherData.gradedSubmissions += assignment.gradedSubmissionsCount || 0;
                        teacherData.manualRegradeCount += assignment.manualRegradeCount || 0;
                        teacherData.aiRegradeCount += assignment.aiRegradeCount || 0;
                        teacherData.subQuestionCount += assignment.subQuestionCount || 0;

                        // Update teacher's weekly activity
                        if (!teacherData.weeklyActivity.has(weekKey)) {
                            teacherData.weeklyActivity.set(weekKey, {
                                week: weekKey,
                                total: 0,
                                published: 0,
                                gradesPublished: 0,
                                unpublished: 0,
                                assignments: 0,  // Add these
                                submissions: 0,  // Add these
                                regrades: 0,    // Add these
                                mir: 0
                            });
                        }
                        const teacherWeek = teacherData.weeklyActivity.get(weekKey);
                        teacherWeek.total++;
                        console.log(assignment)
                        if (assignment.isPublished) {
                            if (assignment.isGradesPublished) {
                                teacherWeek.gradesPublished++;
                            } else {
                                teacherWeek.published++;
                            }
                        } else {
                            teacherWeek.unpublished++;
                        }
                        teacherWeek.assignments++;
                        teacherWeek.published += assignment.isPublished ? 1 : 0;
                        teacherWeek.submissions += assignment.gradedSubmissionsCount || 0;
                        globalTotals.totalManualRegrades += assignment.manualRegradeCount || 0;
                        console.log(assignment)
                        globalTotals.totalSubmissions += assignment.gradedSubmissionsCount;
                        globalTotals.totalSubQuestions += assignment.subQuestionCount || 0;
                        teacherWeek.regrades += (assignment.aiRegradeCount || 0) + (assignment.manualRegradeCount || 0);
                        teacherWeek.mir = calculateMIR(
                            assignment.manualRegradeCount,
                            assignment.subQuestionCount,
                            assignment.gradedSubmissionsCount
                        );
                        globalTotals.totalAiRegrades += assignment.aiRegradeCount || 0;
                    });

                    // Update global totals
                    globalTotals.totalAssignments += classStat.assignmentsTotalCount || 0;
                    globalTotals.publishedAssignments += classStat.assignmentsPublishedCount || 0;
                });

                // Convert weekly activity to sorted array
                teacherData.weeklyActivity = Array.from(teacherData.weeklyActivity.values())
                    .sort((a, b) => new Date(a.week) - new Date(b.week));

                return teacherData;
            });

            setAllStats({
                global: {
                    ...globalTotals,
                    globalMIR: calculateMIR(
                        globalTotals.totalManualRegrades,
                        globalTotals.totalSubQuestions,
                        globalTotals.totalSubmissions
                    ),
                    activeTeachers: teachers.length
                },
                weekly: Array.from(weeklyBuckets.values())
                    .sort((a, b) => new Date(a.weekStart) - new Date(b.weekStart))
                    .slice(-20),
                teachers: processedTeachers,
                classDetails: classDetailsMap,
                assignmentDetails: assignmentDetailsMap
            });
        };

    const GlobalStatsCard = () => (
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Global Statistics</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <StatBox
                    title="Total Assignments"
                    value={allStats.global?.totalAssignments}
                />
                <StatBox
                    title="Total Submissions"
                    value={allStats.global?.totalSubmissions}
                />
                <StatBox
                    title="Global MIR"
                    value={`${allStats.global?.globalMIR.toFixed(2)}%`}
                />
                <StatBox
                    title="Active Teachers"
                    value={allStats.global?.activeTeachers}
                />
            </div>
        </div>
    );

    const WeeklyTrendsChart = () => (
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Weekly Trends</h2>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={allStats.weekly}>
                    <XAxis dataKey="weekStart"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="assignmentsCreated" stroke="#8884d8" name="Assignments Created"/>
                    <Line type="monotone" dataKey="submissionsGraded" stroke="#82ca9d" name="Submissions Graded"/>
                    <Line type="monotone" dataKey="aiRegrades" stroke="#ffc658" name="AI Regrades"/>
                    <Line type="monotone" dataKey="manualRegrades" stroke="#ff7300" name="Manual Regrades"/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    );

    const AssignmentDetailsPopup = ({assignment, onClose}) => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <h2 className="text-2xl font-bold mb-4">{assignment.name}</h2>
                <p><strong>Due Date:</strong> {assignment.dueDatetime}</p>
                <p><strong>Timezone:</strong> {assignment.timezone}</p>
                <p><strong>Is Essay:</strong> {assignment.isEssay ? 'Yes' : 'No'}</p>
                <p><strong>Is Exam:</strong> {assignment.isExam ? 'Yes' : 'No'}</p>
                <p><strong>Is Group Task:</strong> {assignment.isGroupTask ? 'Yes' : 'No'}</p>
                <p><strong>Question Count:</strong> {assignment.questionCount}</p>
                <p><strong>Sub-Question Count:</strong> {assignment.subQuestionCount}</p>
                <p><strong>Total Submissions:</strong> {assignment.totalSubmissionsCount}</p>
                <p><strong>Graded Submissions:</strong> {assignment.gradedSubmissionsCount}</p>
                <p><strong>Reviewed Submissions:</strong> {assignment.reviewedSubmissionsCount}</p>
                <p><strong>AI Regrade Count:</strong> {assignment.aiRegradeCount}</p>
                <p><strong>Manual Regrade Count:</strong> {assignment.manualRegradeCount}</p>
                <h3 className="text-xl font-semibold mt-4 mb-2">Submissions:</h3>
                <ul className="list-disc pl-5">
                    {assignment.submissions.map((submission, index) => (
                        <li key={index}>
                            Grade: {submission.grade}, Late: {submission.late ? 'Yes' : 'No'},
                            Date: {submission.submittedDate}
                        </li>
                    ))}
                </ul>
                <button
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );

    const ClassDetailsPopup = ({classData, onClose}) => {
        // Initialize with default values since they're not in the current data structure
        const [classPrice, setClassPrice] = useState(1499); // Default to $14.99
        const [freeTrialAssignments, setFreeTrialAssignments] = useState(2);


        // Load current values when component mounts
        useEffect(() => {
            const fetchClassSettings = async () => {
                try {
                    const response = await gradeApi.get({
                        path: `/api/courses/${classData.id}/`,
                        config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                    });
                    if (response.data) {
                        setClassPrice(response.data.price || 1499);
                        setFreeTrialAssignments(response.data.free_trial_assignments || 2);
                    }
                } catch (error) {
                    console.error('Failed to fetch class settings:', error);
                }
            };

            console.log(classData)
            if (classData.id) {
                fetchClassSettings();
            }
        }, [classData.id]);

        const updateClassPrice = async (newPrice) => {
            if (!classData.id) {
                return;
            }

            try {
                await gradeApi.patch({
                    path: `/api/courses/${classData.id}/price`,
                    body: {price: newPrice},
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                });
                setClassPrice(newPrice);
                setErrorMessage('');
            } catch (error) {
                console.error('Failed to update class price:', error);
                setErrorMessage('Failed to update class price: ' + error.message);
            }
        };

        const updateFreeTrialAssignments = async (newValue) => {
            if (!classData.id) {
                return;
            }

            try {
                await gradeApi.patch({
                    path: `/api/courses/${classData.id}/free-trial`,
                    body: {free_trial_assignments: newValue},  // Changed from data to body
                    headers: {
                        'Authorization': `Bearer ${user.session_token}`,
                        'Content-Type': 'application/json'
                    }
                });
                setFreeTrialAssignments(newValue);
                setErrorMessage('');
            } catch (error) {
                console.error('Failed to update free trial settings:', error);
                setErrorMessage('Failed to update free trial settings: ' + error.message);
            }
        };

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                    <h2 className="text-2xl font-bold mb-4">{classData.name}</h2>
                    <p><strong>Students:</strong> {classData.studentsCount}</p>
                    <p><strong>Total Assignments:</strong> {classData.assignmentsTotalCount}</p>
                    <p><strong>Published Assignments:</strong> {classData.assignmentsPublishedCount}</p>
                    <p><strong>Grades Published:</strong> {classData.assignmentsGradesPublishedCount}</p>

                    <div className="mt-4">
                        <label className="block mb-2">Class Price</label>
                        <select
                            value={classPrice}
                            className="w-full p-2 border rounded"
                            onChange={(e) => updateClassPrice(parseInt(e.target.value))}
                        >
                            <option value={499}>$4.99</option>
                            <option value={1499}>$14.99</option>
                        </select>
                    </div>

                    <div className="mt-4">
                        <label className="block mb-2">Free Trial Assignments</label>
                        <input
                            type="number"
                            min="0"
                            value={freeTrialAssignments}
                            className="w-full p-2 border rounded"
                            onChange={(e) => updateFreeTrialAssignments(parseInt(e.target.value))}
                        />
                    </div>

                    <h3 className="text-xl font-semibold mt-4 mb-2">Assignments:</h3>
                    <div className="space-y-2">
                        {classData.assignments?.map((assignment, index) => (
                            <div
                                key={index}
                                className="p-2 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
                                onClick={() => setSelectedAssignment(assignment)}
                            >
                                {assignment.name}
                            </div>
                        ))}
                    </div>

                    <button
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    };

    const TeacherCards = () => (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {allStats.teachers.map(teacher => (
                <div
                    key={teacher.id}
                    className="bg-white p-6 rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition-shadow"
                    onClick={() => setSelectedTeacher(teacher)}
                >
                    <h3 className="text-xl font-bold mb-3">{teacher.firstName} {teacher.lastName}</h3>
                    <div className="space-y-2">
                        <p>Assignments: {teacher.assignmentCount}</p>
                        <p>Submissions: {teacher.submissionCount}</p>
                        <p>MIR: {calculateMIR(
                            teacher.manualRegradeCount,
                            teacher.subQuestionCount,
                            teacher.gradedSubmissions
                        ).toFixed(2)}%</p>
                    </div>
                    <div className="mt-4 h-32">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={teacher.weeklyActivity}>
                                <XAxis dataKey="week"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                {/*<Bar stackId="assignments" dataKey="unpublished" fill="#8884d8" name="Not Published"/>*/}
                                <Bar stackId="assignments" dataKey="published" fill="#808080" name="Published"/>
                                <Bar stackId="assignments" dataKey="gradesPublished" fill="#ffd700"
                                     name="Grades Published"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="mt-4">
                        <h4 className="font-semibold mb-2">Classes:</h4>
                        <div className="space-y-1 max-h-40 overflow-y-auto">
                            {teacher.classes.map(classData => (
                                <div
                                    key={classData.id}
                                    className="p-2 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedClass(classData);
                                    }}
                                >
                                    <div className="font-medium">{classData.name}</div>
                                    <div className="text-sm text-gray-600">
                                        {classData.studentsCount} students
                                        • {classData.assignmentsTotalCount} assignments
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
    const TeacherDetailModal = ({teacher, onClose}) => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">
                        {teacher.firstName} {teacher.lastName} - Detailed Statistics
                    </h2>
                    <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={onClose}
                    >
                        ×
                    </button>
                </div>

                <div className="space-y-8">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <StatBox title="Total Assignments" value={teacher.assignmentCount}/>
                        <StatBox title="Total Submissions" value={teacher.submissionCount}/>
                        <StatBox title="Graded Submissions" value={teacher.gradedSubmissions}/>
                        <StatBox
                            title="MIR"
                            value={`${calculateMIR(
                                teacher.manualRegradeCount,
                                teacher.subQuestionCount,
                                teacher.gradedSubmissions
                            ).toFixed(2)}%`}
                        />
                    </div>

                    <div className="space-y-4">
                        <h3 className="text-xl font-bold">Activity Timeline</h3>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={teacher.weeklyActivity}>
                                <XAxis dataKey="week"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" dataKey="assignments" stroke="#8884d8" name="Assignments"/>
                                <Line type="monotone" dataKey="submissions" stroke="#82ca9d" name="Submissions"/>
                                <Line type="monotone" dataKey="regrades" stroke="#ffc658" name="Regrades"/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );

    const StatBox = ({title, value}) => (
        <div className="p-4 border rounded-lg">
            <h3 className="text-gray-600 text-sm">{title}</h3>
            <p className="text-2xl font-bold">{value}</p>
        </div>
    );

    return (
        <div className="p-8 space-y-8">
            <h1 className="text-3xl font-bold mb-8">Analytics Dashboard</h1>

            <GlobalStatsCard/>

            <WeeklyTrendsChart/>

            <WaitlistSection/>

            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-6">Teacher Performance</h2>
                <TeacherCards/>
            </div>

            {errorMessage && (
                <div className="mt-4 p-4 bg-red-100 text-red-700 rounded">
                    {errorMessage}
                </div>
            )}

            {selectedTeacher && (
                <TeacherDetailModal
                    teacher={selectedTeacher}
                    onClose={() => setSelectedTeacher(null)}
                />
            )}

            {selectedClass && (
                <ClassDetailsPopup
                    classData={selectedClass}
                    onClose={() => setSelectedClass(null)}
                />
            )}

            {selectedAssignment && (
                <AssignmentDetailsPopup
                    assignment={selectedAssignment}
                    onClose={() => setSelectedAssignment(null)}
                />
            )}
        </div>
    );
}

export default ADashboard;