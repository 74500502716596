import { Analytics } from '@vercel/analytics/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { AuthProvider } from './/auth/AuthContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routing from './Routing';
import store from './store/store';

import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictModef>
  <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
    <Provider store={store}>
      <AuthProvider>
        <Analytics />
        <Routing />
      </AuthProvider>
    </Provider>
  </PostHogProvider>
  // </React.StrictMode>
);

//
//
// cervel
// another one
// one more commit
// and another one
// If you want to start measuring performance in your app, pass a functionff
// to log results (for example: reportWebVitals(console.log))ff
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
