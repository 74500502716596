import {Check} from 'lucide-react';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Tooltip} from 'react-tooltip';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext.js';
import {formatDateTime} from '../../components/DueTimeDisplayTable';
import {getLogs} from '../../store/teacherSlice.js';
import DragAndDropUpload from '../DragDropAnswer';
import {PDFView} from '../Teacher/PDFRendered.js';
import SUnpublishedFlexibleAssignment from './SUnpublishedFlexibleAssignment/SUnpublishedFlexibleAssignment';

function SPublished({assignment, class_id}) {
    const dispatch = useDispatch();
    const {user} = useAuth();
    const [message, setMessage] = useState('');
    const [submissionData, setSubmissionData] = useState({});
    const [feedbackItems, setFeedbackItems] = useState({});
    const [groupStudents, setGroupStudents] = useState([]);
    const [file, setFile] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const pollingIntervalRef = useRef(null);
    const [showSubmissionLog, setShowSubmissionLog] = useState(false);
    const [showSavedPopup, setShowSavedPopup] = useState(false);

    useEffect(() => {
        getAllLogs();

        if (assignment?.submissionId) {
            startPolling(assignment.submissionId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment]);

    const {data: logs, isLoading: isLogsLoading} = useSelector(
        state => state?.teacherReducer?.logs
    );

    const startPolling = submissionId => {
        if (isPolling) {
            return;
        }
        setIsPolling(true);
        pollingIntervalRef.current = setInterval(() => {
            checkGradingStatus(submissionId);
        }, 5000);
    };

    const stopPolling = () => {
        setIsPolling(false);
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }
    };

    const checkGradingStatus = async submissionId => {
        try {
            const response = await gradeApi.get({
                path: `/api/submission/${submissionId}/grading-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            if (response.data.is_grading_done) {
                stopPolling();
                setMessage('Grading completed. Refreshing submission data...');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error checking grading status:', error);
            stopPolling();
            setMessage('Error checking grading status. Please refresh the page.');
        }
    };

    useEffect(() => {
        getAssignmentSubmission();

        return () => {
            stopPolling();
        };
    }, []);

    useEffect(() => {
    }, []);

    useEffect(() => {
        fetchGroupStudents();
    }, [assignment.id]);

    const handleResubmit = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file_data', file);
        formData.append('user_id', user.user.id);
        formData.append('submitter_id', user.user.id);

        try {
            setMessage('Resubmission in progress... please wait');
            setIsButtonDisabled(true);

            const response = await gradeApi.post({
                path: `/api/assignment/${assignment.id}/submit-or-resubmit/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            const submissionId = response.data.id;
            setMessage('Assignment resubmitted successfully! Please wait, grading...');

            const gradingResponse = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/ai-grade/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (gradingResponse.status === 200) {
                setMessage('Assignment is being graded... You can leave the page.');
                setShowSavedPopup(true);
                setTimeout(() => setShowSavedPopup(false), 5000);
                startPolling(submissionId);
            } else {
                throw new Error('Grading failed. Please try again later.');
            }
        } catch (error) {
            console.error('Error resubmitting assignment:', error);
            setIsButtonDisabled(false);
            setMessage(error.response?.data?.error || 'An unknown error occurred');
        }
    };

    const getAssignmentSubmission = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/v2/assignments/${assignment.id}/user/${user.user.id}/submission/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;
            setSubmissionData(data);
            setFeedbackItems(data?.feedback || {});
        } catch (error) {
            setSubmissionData({submitted: false});
            setMessage(error.response?.data?.error || 'An error occurred while fetching the submission.');
        }
    };

    const getAllLogs = async () => {
        await dispatch(
            getLogs({
                userId: user.user.id,
                token: user.session_token,
                assignmentId: assignment.id
            })
        );
    };

    const hasRubricItem = (subquestionId, rubricId) => {
        if (!feedbackItems) {
            return false;
        }
        if (!subquestionId) {
            return false;
        }
        if (subquestionId in feedbackItems) {
            let rubricItems = feedbackItems[subquestionId]?.rubric_items;
            for (let i = 0; i < rubricItems.length; i++) {
                if (rubricItems[i].id === rubricId) {
                    return true;
                }
            }
        }
        return false;
    };

    const getFeedback = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].feedback;
        }
        return '';
    };

    const handleRegradeReq = subquestionId => {
        setFeedbackItems(prevFeedbackItems => ({
            ...prevFeedbackItems,
            [subquestionId]: {
                ...prevFeedbackItems[subquestionId],
                regrade_request: true,
                regrade_text: feedbackItems[subquestionId]?.regrade_text || '',
                edit_mode: true
            }
        }));
    };

    const fetchGroupStudents = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/get_students/${assignment.id}/student/${user.user.id}`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGroupStudents(response.data);
        } catch (error) {
            console.error('Error fetching group students:', error);
        }
    };

    const handleRegradeReqSave = async subquestionId => {
        const regradeReason = feedbackItems[subquestionId]?.regrade_text || '';
        const submissionID = submissionData?.id;

        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/submissions/${submissionID}/regrade-request/`,
                body: {
                    subquestionId: subquestionId,
                    regradeRequest: true,
                    regradeText: regradeReason
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            setFeedbackItems(prevFeedbackItems => ({
                ...prevFeedbackItems,
                [subquestionId]: {
                    ...prevFeedbackItems[subquestionId],
                    regrade_request: true,
                    regrade_text: regradeReason,
                    edit_mode: false
                }
            }));
        } catch (error) {
            console.error('Error updating regrade request:', error);
        }
    };

    const SortRubricItemsAndDisplay = ({subQuestion}) => {
        subQuestion.rubricItems.sort((a, b) => {
            if (a.value == b.value) {
                return 0;
            }
            return a.value > b.value ? -1 : 1;
        });
        return (
            <div className="rounded-xl overflow-hidden border border-gray-300">
                {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
                    <div
                        key={rubricIndex}
                        className={`
              flex items-center px-3 py-2
              ${hasRubricItem(subQuestion.id, rubricItem.id) ? 'bg-orange-200' : 'bg-white'}
              ${rubricIndex !== 0 ? 'border-t border-gray-300' : ''}
            `}
                    >
                        <div className="flex items-center flex-1">
                            <div
                                className={`
                w-5 h-5 rounded-full border border-gray-400 
                flex items-center justify-center mr-3
                ${hasRubricItem(subQuestion.id, rubricItem.id) ? 'bg-gray-500 border-gray-700' : ''}
              `}
                            >
                                {hasRubricItem(subQuestion.id, rubricItem.id) && (
                                    <Check className="w-3 h-3 text-white stroke-2"/>
                                )}
                            </div>
                            <div className="flex items-center">
                                <span className="text-xl font-medium mr-3">{rubricItem.value}</span>
                                <span className="text-sm">{rubricItem.descriptor}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const handleRegradeReqCancel = subquestionId => {
        setFeedbackItems(prevFeedbackItems => ({
            ...prevFeedbackItems,
            [subquestionId]: {
                ...prevFeedbackItems[subquestionId],
                regrade_request: false,
                regrade_text: '',
                edit_mode: false
            }
        }));
    };

    const handleEditRegradeReq = subquestionId => {
        setFeedbackItems(prevFeedbackItems => ({
            ...prevFeedbackItems,
            [subquestionId]: {
                ...prevFeedbackItems[subquestionId],
                edit_mode: true
            }
        }));
    };

    // const toggleSolution = (questionIndex, subQuestionIndex) => {
    //     setShowSolutions(prevState => {
    //         const newState = {...prevState};
    //         if (!newState[questionIndex]) {
    //             newState[questionIndex] = [];
    //         }
    //         newState[questionIndex][subQuestionIndex] = !newState[questionIndex][subQuestionIndex];
    //         return newState;
    //     });
    // };

    // const correctOptionIdentifier = (subQuestion) => {
    //     const correctOptionIndex = subQuestion.mcqOptions.findIndex(option => option.isCorrect);
    //     return correctOptionIndex !== -1 ? String.fromCharCode(65 + correctOptionIndex) : "No correct answer provided";
    // };

    return (
        <div className="flex flex-col w-full overflow-x-hidden">
            {/* navigation trail */}
            <div className="flex flex-col fixed w-full pb-5 pt-10 bg-white px-5 mb-2">
                <div className="flex items-center space-x-2 mb-4">
                    <Link to="/sclasses" className="text-black text-lg font-medium ml-4">
                        My Classes
                    </Link>
                    <Link to={`/sclasses/${assignment?.course_id}/sassignments`}>
                        <button>
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                        </button>
                    </Link>
                    <Link
                        to={`/sclasses/${assignment?.course_id}/sassignments`}
                        className="text-black text-lg font-medium ml-4"
                    >
                        {assignment?.course?.name}
                    </Link>
                    <Link to={`/sclasses/${assignment?.course_id}/sassignments`}>
                        <button>
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                        </button>
                    </Link>
                    <h1 className="text-black text-lg font-medium ml-4">{assignment?.title}</h1>
                </div>
            </div>

            {/* main content */}
            <div className="flex h-[calc(100vh-9rem)] mt-[7rem]">
                {showSavedPopup && (
                    <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                        Assignment resubmitted successfully! You can leave this page.
                    </div>
                )}
                {/* student work */}
                <div className="w-1/2 p-4">
                    {!submissionData?.submitted ? (
                        <div className="w-full h-full flex flex-col items-center justify-center bg-gray-100 rounded-xl">
                            <img src="/assets/folder.png" alt="Missing Submission" className="w-24 h-24 mb-4"/>
                            <p className="text-xl font-semibold text-gray-700">Missing Submission</p>
                        </div>
                    ) : submissionData?.essay_text ? (
                        <div className="w-full h-full overflow-auto bg-white border border-gray-300 p-4 rounded-xl">
                            <pre className="whitespace-pre-wrap font-sans">{submissionData?.essay_text}</pre>
                        </div>
                    ) : assignment?.type === 'flexible_problem_set' ? (
                        <div
                            className="bg-white h-full border border-gray-200 rounded-lg shadow-sm p-6 overflow-auto mt-[10px]">
                            <SUnpublishedFlexibleAssignment
                                isReadMode={true}
                                assignment_id={assignment.id}
                                assignment={assignment}
                                class_id={class_id}
                                studentId={user.user.id}
                            />
                        </div>
                    ) : (
                        <div
                            className="ml-5 mr-5 w-1/2 p-4 h-[calc(100vh-10rem)] min-w-full border border-gray-300 rounded-xl">
                            <div className="w-full h-full relative flex-1">
                                <PDFView
                                    url={`${apiUrl}/api/pdf-file/${submissionData?.file.filename}`}
                                    className="absolute inset-0 flex-1"
                                />
                            </div>
                        </div>
                    )}
                </div>

                {/* feedback section */}
                <div className="w-1/2 overflow-y-auto p-8">
                    <div className="flex flex-col">
                        {submissionData?.submitted && (
                            <>
                                <div className="mb-3 text-smallish">
                                    {submissionData?.submitted && (
                                        <>
                                            <h2 className="text-3xl font-bold mb-4">
                                                Score: {submissionData?.grade}/{assignment?.max_score}
                                            </h2>

                                            {/* Submission Log Section */}
                                            <div className="flex flex-col w-full mb-5">
                                                <button
                                                    type="button"
                                                    className="flex items-center text-sm mb-2"
                                                    onClick={() => setShowSubmissionLog(!showSubmissionLog)}
                                                >
                                                    <img
                                                        src="/assets/back_arrow.png"
                                                        className={`size-5 opacity-60 transform ${showSubmissionLog ? 'rotate-90' : '-rotate-90'} mr-2`}
                                                        alt="Toggle submission log"
                                                    />
                                                    Submission Log
                                                </button>
                                            </div>

                                            {showSubmissionLog && (
                                                <div className="flex flex-col w-full">
                                                    {logs?.entities?.length > 0 ? (
                                                        logs.entities.map((log, index) => (
                                                            <div key={index} className="mb-2 ml-4">
                                                                <p className="text-sm text-gray-700">
                                                                    {logs.totalCount - index}. Submitted at{' '}
                                                                    {formatDateTime(log.submissionDate, user.user.timezone)} by{' '}
                                                                    <span
                                                                        id={`user${log.firstName + log.userId}`}
                                                                        className="relative cursor-pointer hover:text-gray-900"
                                                                    >
                                    <strong>{log.fullName}</strong>
                                  </span>
                                                                </p>
                                                                <Tooltip
                                                                    anchorSelect={`#user${log.firstName + log.userId}`}
                                                                    place="bottom"
                                                                >
                                                                    <p>ID: {log.userId}</p>
                                                                    <p>Email: {log.email}</p>
                                                                </Tooltip>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p className="text-sm text-gray-700 ml-4">No submissions made
                                                            yet.</p>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {/* Warning message moved here and text size increased */}
                                {!assignment?.is_grades_published &&
                                    assignment?.allow_pregrade &&
                                    submissionData?.submission_count == 1 && (
                                        <div className="bg-yellow-50/80 border-l-4 border-yellow-400 p-4 mb-6">
                                            <div className="flex items-start">
                                                <div className="flex-shrink-0">
                                                    <img
                                                        src="/assets/tclasses/warning.png"
                                                        alt="Warning"
                                                        className="h-6 w-6 text-yellow-400"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <p className="text-base text-yellow-700 font-semibold mb-1">
                                                        These grades were produced by GradeWiz AI with NO OVERSIGHT from
                                                        professor.
                                                    </p>
                                                    <p className="text-base text-yellow-700">
                                                        Your final grade MAY BE DIFFERENT. Use these grades for your
                                                        REFERENCE
                                                        ONLY.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}
                    </div>

                    {groupStudents.length > 0 && (
                        <div>
                            <p>Group Members:</p>
                            <p>
                                {Array.isArray(groupStudents) && groupStudents.length > 0
                                    ? groupStudents.map((student, index) => (
                                        <span key={index}>
                        {student[0]} ({student[1]}){index < groupStudents.length - 1 ? ', ' : ''}
                      </span>
                                    ))
                                    : 'No students to display'}
                            </p>
                        </div>
                    )}

                    {assignment?.questions?.map((question, questionIndex) => (
                        <div key={questionIndex} className="mb-8">
                            <h2 className="text-xl font-bold mb-4">
                                {assignment.type === 'essay'
                                    ? `Question ${questionIndex + 1}: ${question.description}`
                                    : `Question ${questionIndex + 1}:`}
                            </h2>

                            {question.subQuestions.map((subQuestion, subQuestionIndex) => (
                                <div key={subQuestionIndex} className="mb-6 ml-4">
                                    {assignment.type === 'essay' ? (
                                        <h3 className="font-semibold mb-2">
                                            {`${String.fromCharCode(97 + subQuestionIndex)}) ${subQuestion.question}`}
                                        </h3>
                                    ) : assignment.type === 'flexible_problem_set' ? (
                                        !question.is_without_sub_questions && (
                                            <h3 className="font-semibold mb-2">
                                                {`${String.fromCharCode(97 + subQuestionIndex)}) ${subQuestion.question}`}
                                            </h3>
                                        )
                                    ) : (
                                        <h3 className="font-bold mb-1 pt-4">
                                            {questionIndex + 1}. {String.fromCharCode(97 + subQuestionIndex)})
                                        </h3>
                                    )}

                                    <div className="flex flex-col gap-2 mb-2 mt-3">
                                        <SortRubricItemsAndDisplay subQuestion={subQuestion}/>
                                    </div>

                                    <div className="mt-2">{getFeedback(subQuestion.id)}</div>

                                    {submissionData?.submitted &&
                                        (feedbackItems[subQuestion.id]?.regrade_request &&
                                        !feedbackItems[subQuestion.id]?.edit_mode ? (
                                            <div className="mt-4 bg-orange-100 p-4 rounded-xl">
                                                <h4 className="font-semibold mb-2">
                                                    Regrade Request: {feedbackItems[subQuestion.id]?.regrade_text}
                                                </h4>
                                                <button
                                                    className="mt-1 mr-2 px-5 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                                    onClick={() => handleEditRegradeReq(subQuestion.id)}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    className="mt-1 px-3 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black"
                                                    onClick={() => handleRegradeReqCancel(subQuestion.id)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) : feedbackItems[subQuestion.id]?.edit_mode ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="Reason for regrade request"
                                                    value={feedbackItems[subQuestion.id]?.regrade_text || ''}
                                                    className="mt-2 border rounded-xl px-4 py-2 mb-2 w-full focus:border-orange-200 focus:ring-gray-400 focus:outline-none"
                                                    onChange={e =>
                                                        setFeedbackItems(prevFeedbackItems => ({
                                                            ...prevFeedbackItems,
                                                            [subQuestion.id]: {
                                                                ...prevFeedbackItems[subQuestion.id],
                                                                regrade_text: e.target.value
                                                            }
                                                        }))
                                                    }
                                                />
                                                <button
                                                    className="mt-1 mr-2 px-3 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                                    onClick={() => handleRegradeReqSave(subQuestion.id)}
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    className="mt-1 px-3 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black"
                                                    onClick={() => handleRegradeReqCancel(subQuestion.id)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="flex flex-col">
                                                {/* Top row with buttons */}
                                                <div className="flex justify-between items-center mt-3">
                                                    {assignment?.course?.allow_regrades && (
                                                        <button
                                                            className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black"
                                                            onClick={() => handleRegradeReq(subQuestion.id)}
                                                        >
                                                            Request Regrade
                                                        </button>
                                                    )}

                                                    {/*{assignment?.type !== 'essay' && !(!assignment?.is_grades_published && (assignment?.allow_pregrade && submissionData?.submission_count == 1)) && (*/}
                                                    {/*    <button*/}
                                                    {/*        type="button"*/}
                                                    {/*        className="flex items-center text-sm"*/}
                                                    {/*        onClick={() => toggleSolution(questionIndex, subQuestionIndex)}*/}
                                                    {/*    >*/}
                                                    {/*        <img*/}
                                                    {/*            src="/assets/back_arrow.png"*/}
                                                    {/*            className={`size-5 opacity-60 transform ${showSolutions[questionIndex]?.[subQuestionIndex] ? 'rotate-90' : '-rotate-90'} mr-2`}*/}
                                                    {/*            alt="Toggle solution"*/}
                                                    {/*        />*/}
                                                    {/*        Answer Key*/}
                                                    {/*    </button>*/}
                                                    {/*)}*/}
                                                </div>

                                                {/* Solution content below */}
                                                {/*{assignment?.type !== 'essay' && showSolutions[questionIndex]?.[subQuestionIndex] && (*/}
                                                {/*    <div*/}
                                                {/*        className="mt-2 p-4 border border-gray-200 rounded-lg bg-gray-50">*/}
                                                {/*        <p className="text-gray-800">*/}
                                                {/*            {subQuestion.questionType !== "mcq"*/}
                                                {/*                ? (subQuestion.solution || "No correct answer provided")*/}
                                                {/*                : `Correct Answer: Option ${correctOptionIdentifier(subQuestion)}`*/}
                                                {/*            }*/}
                                                {/*        </p>*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ))}
                    {!assignment?.is_grades_published && (
                        <div className="mt-8 border-t pt-8">
                            <h3 className="text-xl font-bold mb-4">Resubmit Assignment</h3>
                            <div className="mb-6">
                                {assignment?.type === 'pdf_problem_set' && (
                                    <div>
                                        <div className="mb-6">
                                            <DragAndDropUpload onFileChange={setFile}/>
                                        </div>
                                        <form className="flex items-center space-x-4" onSubmit={handleResubmit}>
                                            <button
                                                type="submit"
                                                disabled={isButtonDisabled}
                                                className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
                                                    isButtonDisabled
                                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                        : 'bg-orange-200 hover:bg-orange-300 text-black'
                                                }`}
                                            >
                                                {isButtonDisabled ? (
                                                    <>
                                                        <span className="opacity-0">Resubmit</span>
                                                        <div
                                                            className="absolute inset-0 flex items-center justify-center">
                                                            <div
                                                                className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    'Resubmit'
                                                )}
                                            </button>
                                            {isButtonDisabled && (
                                                <button
                                                    type="button"
                                                    className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                                    onClick={() => {
                                                        setIsButtonDisabled(false);
                                                        setMessage('');
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            )}
                                        </form>
                                    </div>
                                )}
                                {assignment?.type === 'flexible_problem_set' && (
                                    <div
                                        className="bg-white h-full border border-gray-200 rounded-lg shadow-sm p-6 overflow-auto mt-[10px]">
                                        <SUnpublishedFlexibleAssignment
                                            isPregradeMode
                                            assignment_id={assignment.id}
                                            assignment={assignment}
                                            class_id={class_id}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {assignment?.type === 'essay' && submissionData?.submitted && (
                        <div className="w-full bg-white border border-gray-300 p-4 rounded-xl">
                            <style>{`
                .feedback-content h2 {
                  font-size: 20px;
                  font-weight: var(--h2-font-weight);
                  margin: var(--h2-margin);
                }
                .feedback-content p {
                  margin: var(--p-margin);
                }
                .feedback-content ul {
                  list-style-type: disc;
                  padding: var(--ul-padding);
                  margin: var(--ul-margin);
                }
                .feedback-content li {
                  margin: var(--li-margin);
                }
              `}</style>
                            <div
                                dangerouslySetInnerHTML={{__html: submissionData?.file?.filename}}
                                className="feedback-content"
                                style={{
                                    '--h2-font-size': '1.5em',
                                    '--h2-font-weight': 'bold',
                                    '--h2-margin': '1em 0 0.5em',
                                    '--p-margin': '0 0 1em',
                                    '--ul-padding': '0 0 0 2em',
                                    '--ul-margin': '0 0 1em',
                                    '--li-margin': '0 0 0.5em',
                                    height: 'auto',
                                    overflow: 'visible'
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>

            {message && (
                <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg">
                    {message}
                </div>
            )}
        </div>
    );
}

export default SPublished;
