import React from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {gradeApi} from "../../api";
import Cookies from 'js-cookie';

const LTILaunchPage = () => {
    const [searchParams] = useSearchParams();
    const sessionToken = searchParams.get('session_token');
    const navigate = useNavigate();

    const handleLaunch = async () => {
        // Open new tab first
        const newTab = window.open('', '_blank');

        if (newTab) {
            try {
                // Show loading state
                newTab.document.write(`
          <html>
            <head>
              <style>
                body { 
                  display: flex; 
                  justify-content: center; 
                  align-items: center; 
                  height: 100vh; 
                  margin: 0;
                  font-family: system-ui, -apple-system, sans-serif;
                }
                .loading { 
                  font-size: 1.2rem; 
                  color: #4B5563;
                }
              </style>
            </head>
            <body>
              <div class="loading">Launching GradeWiz...</div>
            </body>
          </html>
        `);

                // Call the LTI login endpoint
                const response = await gradeApi.post({
                    path: '/api/lti-login/',
                    body: {session_token: sessionToken}
                });

                if (response.data && !('error' in response.data)) {
                    const cookiesScript = `
            // Set all required cookies
            document.cookie = "session_token=${response.data.session_token}; path=/";
            document.cookie = "user_id=${response.data.user.id}; path=/";
            document.cookie = "user_email=${response.data.user.email}; path=/";
            document.cookie = "is_teacher=${String(response.data.user.is_teacher)}; path=/";
            document.cookie = "first_name=${response.data.user.first_name}; path=/";
            document.cookie = "last_name=${response.data.user.last_name}; path=/";
            document.cookie = "is_verified=${String(response.data.user.is_verified)}; path=/";
            document.cookie = "timezone=${String(response.data.user.timezone)}; path=/";

            // Redirect based on user type
            window.location.href = ${
                        response.data.user.is_admin ?
                            '"/admin"' :
                            response.data.user.is_teacher ?
                                '"/tclasses"' :
                                '"/sclasses"'
                    };
          `;

                    newTab.document.write(`<script>${cookiesScript}</script>`);
                } else {
                    throw new Error(response.data?.error || 'Login failed');
                }

                // Navigate original tab back to login
                navigate('/login');
            } catch (error) {
                console.error('LTI Launch error:', error);
                newTab.close();
                navigate('/login');
            }
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white rounded-lg shadow-md p-6 w-96">
                <div className="text-center">
                    <h1 className="text-xl font-semibold mb-4">Launch GradeWiz</h1>
                    <button
                        className="w-full bg-black text-white font-semibold py-2 px-4 rounded-full hover:bg-gray-800 transition-colors duration-200"
                        onClick={handleLaunch}
                    >
                        Launch
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LTILaunchPage;