import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';

const RegradeRequests = () => {
    const [regradeRequests, setRegradeRequests] = useState([]);
    const [error, setError] = useState(null);
    const {user} = useAuth();

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    };

    useEffect(() => {
        const fetchRegradeRequests = async () => {
            try {
                const response = await gradeApi.get({
                    path: `/api/regrade-requests/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                setRegradeRequests(response.data);
            } catch (err) {
                console.log(err);
                setError('Error fetching regrade requests');
            }
        };
        fetchRegradeRequests();
    }, []);

    const LinkToTDetails = ({submission}) => {
        var subquestion_id = null;
        for (var key in submission.feedback) {
            subquestion_id = key;
            break;
        }
        for (var request in regradeRequests) {
            if (regradeRequests[request].id === submission.id) {
                var subquestion_id = null;
                for (var subquestion in regradeRequests[request].feedback) {
                    if (regradeRequests[request].feedback[subquestion].regrade_request) {
                        subquestion_id = subquestion
                        break;
                    }
                }
            }
        }
        if (subquestion_id === null) {
            console.error("RegradeRequest does not have Subquestion")
            return (
                <Link
                    to={`/tclasses/${submission.assignment.course_id}/tassignments/${submission.assignment.id}/tsubmissions/${submission.id}/tdetails`}
                    className="hover:underline"
                >
                    {`${submission.user.first_name} ${submission.user.last_name}`}
                </Link>
            )
        }
        return (
            <Link
                to={`/tclasses/${submission.assignment.course_id}/tassignments/${submission.assignment.id}/tsubmissions/${submission.id}/tdetails/${subquestion_id}`}
                className="hover:underline"
            >
                {`${submission.user.first_name} ${submission.user.last_name}`}
            </Link>
        )
    }

    if (error) {
        return <div>{error}</div>;
    }

    const formatSubquestions = (assignment, feedback) => {
        console.log(assignment);
        console.log(feedback);
        const pendingRegrades = [];
        assignment.questions.forEach((question, questionIndex) => {
            question.subQuestions.forEach((subQuestion, subQuestionIndex) => {
                const subId = subQuestion.id.toString(); // Ensure subId is a string if keys are strings
                if (feedback[subId]?.regrade_request) {
                    const questionNumber = questionIndex + 1;
                    const subquestionLetter = String.fromCharCode(97 + subQuestionIndex);
                    pendingRegrades.push(`Q${questionNumber}${subquestionLetter}`);
                }
            });
        });
        return pendingRegrades.join(', ');
    };

    return (
        <div id="Statistics" className="flex flex-col space-y-4 w-full p-8">
            <div id="Overview" className="flex flex-row space-x-full w-full justify-between">
                <h1 className="text-black text-xl font-medium">Regrade Requests</h1>
            </div>
            <div className="bg-white sexy-border p-6">
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}
                <table className="w-full border-collapse">
                    <thead>
                    <tr className="border-b border-border">
                        <th className="py-4 text-left text-gray custom-16">Student</th>
                        <th className="py-4 text-left text-gray custom-16">Submission Date</th>
                        <th className="py-4 text-left text-gray custom-16">Grade</th>
                        <th className="py-4 text-left text-gray custom-16">Assignment</th>
                        <th className="py-4 text-left text-gray custom-16">Pending Regrades</th>
                    </tr>
                    </thead>
                    {regradeRequests.length > 0 ? (
                        <tbody>
                        {regradeRequests.map(submission => (
                            <tr key={submission.id} className="border-b border-border">
                                <td className="text-left text-black custom-16-semi py-4">
                                    <LinkToTDetails submission={submission}/>
                                </td>
                                <td className="text-left text-black custom-16-med py-4">
                                    {submission.submission_date}
                                </td>
                                <td className="text-left text-black custom-16-med py-4">{submission.grade}</td>
                                <td className="text-left text-black custom-16-med py-4">
                                    {truncateText(submission.assignment.title, 20)}
                                </td>
                                <td className="text-left text-black custom-16-med py-4">
                                    {formatSubquestions(submission.assignment, submission.feedback)}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    ) : (
                        <tbody>
                        <tr>
                            <td colSpan="4" className="text-center py-4 text-gray-500">
                                No regrade requests found
                            </td>
                        </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
};

export default RegradeRequests;
